import React from "react";

const EmailResponseModal = ({ emailStatus, onClose }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">New password has been sent</p>

      <p className="modal__confirm-cancel" onClick={onClose}>
        Close
      </p>
    </div>
  );
};

export default EmailResponseModal;
