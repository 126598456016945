import "./TopicPage.css";
import TopicPageHeader from "../TopicPageHeader/TopicPageHeader";
import TopicPageMain from "../TopicPageMain/TopicPageMain";

function TopicPage() {
  return (
    <>
      <TopicPageHeader />
      <TopicPageMain />
    </>
  );
}

export default TopicPage;
