import React from "react";

const DeleteEventModal = ({ handleEventDelete, onClose }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to delete this event?
      </p>
      <button className="button button-error" onClick={handleEventDelete}>
        Yes, delete event
      </button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default DeleteEventModal;
