import "./TopicPageMain.css";
import { useCallback, useEffect, useState } from "react";
import DiscussionQuestionCard from "../DiscussionQuestionCard/DiscussionQuestionCard";
import TopicResourceCard from "../TopicResourceCard/TopicResourceCard";
import {
  getEventTopic,
  getTopicDiscussionQuestions,
  getTopicResources,
} from "../../utils/api";
import { useParams } from "react-router-dom";

function TopicPageMain() {
  const params = useParams();
  const [topicResources, setTopicResources] = useState([]);
  const [topic, setTopic] = useState({});
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [discussionQuestions, setDiscussionQuestions] = useState([]);

  useEffect(() => {
    if (!loadAttempt) {
      getEventTopic(params.id)
        .then((topicResponse) => {
          setTopic(topicResponse);
          getTopicResources({
            topicidarray: topicResponse.topicresources,
          }).then((topicResourcesResponse) => {
            setTopicResources(topicResourcesResponse);
          });
          getTopicDiscussionQuestions({
            topicidarray: topicResponse.topicquestions,
          }).then((discussionResponse) => {
            setDiscussionQuestions(discussionResponse);
          });
        })
        .finally(() => {
          setLoadAttempt(true);
        });
    }
  }, [loadAttempt, params.id]);

  const reload = useCallback(() => {
    setLoadAttempt(false);
  }, []);

  return (
    <>
      <main className="main">
        <>
          <div className="main__page-section">
            <h2 className="main__headers">Topic Resources</h2>

            <section className="main__card-section" id="card-section">
              {topicResources?.map((resource) => (
                <TopicResourceCard
                  key={resource._id}
                  item={resource}
                  topic={topic}
                  reload={reload}
                />
              ))}
            </section>
          </div>
        </>
        <>
          <div className="main__page-section">
            <h2 className="main__headers">Topic Discussion Questions</h2>

            <section className="main__card-section" id="card-section">
              {discussionQuestions?.map((question) => (
                <DiscussionQuestionCard
                  key={question._id}
                  questioninfo={question}
                  topic={topic}
                  reload={reload}
                />
              ))}
            </section>
          </div>
        </>
      </main>
    </>
  );
}

export default TopicPageMain;
