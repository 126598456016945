import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

const EditEventModal = ({ isOpen, onClose, onEditEvent, currentEvent }) => {
  const currentEventName = currentEvent?.eventname;
  const currentEventStartDate = currentEvent?.eventstartdate;
  const currentEventEndDate = currentEvent?.eventenddate;
  const currentEventWelcome = currentEvent?.eventwelcomemessage;
  const currentEventAgenda = currentEvent?.eventagenda;
  const currentEventLogo = currentEvent?.eventlogo;

  const [eventName, setEventName] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventWelcome, setEventWelcome] = useState("");
  const [eventAgenda, setEventAgenda] = useState("");

  useEffect(() => {
    setEventName(currentEventName);
    setEventStartDate(currentEventStartDate.slice(0, 10));
    setEventEndDate(currentEventEndDate.slice(0, 10));
    setEventWelcome(currentEventWelcome);
    setEventAgenda(currentEventAgenda);
  }, [
    currentEventName,
    currentEventStartDate,
    currentEventEndDate,
    currentEventWelcome,
    currentEventAgenda,
    currentEventLogo,
  ]);

  function handleEventNameChange(evt) {
    setEventName(evt.target.value);
  }

  function handleEventStartDateChange(evt) {
    setEventStartDate(evt.target.value);
  }

  function handleEventEndDateChange(evt) {
    setEventEndDate(evt.target.value);
  }

  function handleWelcomeMessageChange(evt) {
    setEventWelcome(evt.target.value);
  }

  function handleEventAgendaChange(evt) {
    setEventAgenda(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const update_event = {};
    update_event.eventname = eventName;
    update_event.eventstartdate = eventStartDate;
    update_event.eventenddate = eventEndDate;
    update_event.eventwelcomemessage = eventWelcome;
    update_event.eventagenda = eventAgenda;
    onEditEvent(update_event);
  }

  return (
    <ModalWithForm
      buttonText="Save Event Changes"
      title="Edit Event"
      onClose={onClose}
      name="new-event-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Event Name
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          maxLength="128"
          placeholder="Name"
          value={eventName}
          onChange={handleEventNameChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-imagelabel">
        Event Start Date
        <input
          className="modal__input"
          id="modal-link"
          type="date"
          name="event_dates"
          placeholder="Date"
          value={eventStartDate}
          onChange={handleEventStartDateChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-imagelabel">
        Event End Date
        <input
          className="modal__input"
          id="modal-link"
          type="date"
          name="event_dates"
          placeholder="Date"
          value={eventEndDate}
          onChange={handleEventEndDateChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        Welcome Message
        <textarea
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Welcome Message"
          value={eventWelcome}
          onChange={handleWelcomeMessageChange}
          required
        />
      </label>
      {/* <label className="modal__label" id="modal-namelabel">
        Agenda
        <textarea
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Agenda"
          value={eventAgenda}
          onChange={handleEventAgendaChange}
          disabled
        />
      </label> */}
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};
export default EditEventModal;
