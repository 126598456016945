import React from "react";

function RenderLogo({ imageContent, classes }) {
  if(imageContent != undefined) {
    return (
      <img alt="" src={`data:image;base64,${imageContent}`} className={classes} />
    );
  } else {
    return false;
  }
}

export default RenderLogo;
