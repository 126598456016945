import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import { editProfileInfo, getUser } from "../../utils/api";

const EditUserInfoModal = ({ isOpen, onClose, selectedUser }) => {
  console.log(selectedUser);
  // const [username, setusername] = useState("");
  //
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  //
  const [userEmail, setUserEmail] = useState("");
  const [userAffiliation, setUserAffiliation] = useState("");
  const [userStatus, setUserStatus] = useState("");

  function handleUserAffiliationChange(evt) {
    setUserAffiliation(evt.target.value);
  }

  function handleUserStatus(evt) {
    setUserStatus(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const [id] = selectedUser;
    editProfileInfo({
      id,
      useraffiliation: userAffiliation,
      useremail: userEmail,
      userstatus: userStatus,
      useravatar: null,
    })
      .then((res) => {
        console.log(res);
        onClose();
      })
      .catch((err) => {
        console.log(err.err);
      });
  }

  useEffect(() => {
    if (selectedUser.length === 1) {
      const [id] = selectedUser;
      getUser({ id: id }).then((res) => {
        console.log(res);
        // setusername(res.username);
        setUserFirstName(res.firstName);
        setUserLastName(res.lastName);
        setUserEmail(res.useremail);
        setUserAffiliation(res.useraffiliation);
        setUserStatus(res.userstatus);
      });
    }
  }, []);

  return (
    <ModalWithForm
      buttonText="Update User"
      title="Update User"
      onClose={onClose}
      name="new-user-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        First Name*
        <input
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          maxLength="32"
          placeholder="Name"
          value={userFirstName}
          disabled
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        Last Name*
        <input
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          maxLength="32"
          placeholder="Name"
          value={userLastName}
          disabled
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        Email*
        <input
          className="modal__input"
          id="modal-name"
          type="email"
          name="name"
          minLength="1"
          placeholder="Email"
          value={userEmail}
          disabled
          required
        />
      </label>
      <label className="modal__label" id="modal-modal-namelabel">
        Affiliation*
        <input
          className="modal__input"
          id="modal-name"
          type="text"
          name="affiliation"
          minlength="1"
          maxlength="64"
          placeholder="Affiliation"
          value={userAffiliation}
          onChange={handleUserAffiliationChange}
          required
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
      <p className="modal__subheading">User Status</p>
      <div className="radio-buttons">
        <label className="radio-description">
          <input
            className="button"
            type="radio"
            value="active"
            name="userstatus"
            checked={userStatus === "active"}
            onChange={handleUserStatus}
          />
          Active
        </label>

        <label className="radio-description">
          <input
            className="button"
            type="radio"
            value="inactive"
            name="userstatus"
            checked={userStatus === "inactive"}
            onChange={handleUserStatus}
          />
          Inactive
        </label>
      </div>
    </ModalWithForm>
  );
};

export default EditUserInfoModal;
