import "./ProfileHeader.css";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import BC_Logo from "../../images/BC_Logo.svg";
import EditProfileModal from "../EditProfileModal/EditProfileModal";
import LogoutModal from "../LogoutModal/LogoutModal";
import RenderLogo from "../RenderLogo";
import { isUserAdmin } from "../../utils/auth";

function ProfileHeader() {
  const { currentLoggedInUser } = useContext(CurrentLoggedInUserContext);
  const currentLoggedInUserAvatar =
    currentLoggedInUser?.contentType?.includes("png");
  const currentLoggedInUserFirstName = currentLoggedInUser?.firstName || [];
  const currentLoggedInUserLastName = currentLoggedInUser?.lastName || [];
  const currentLoggedInUserAffiliation =
    currentLoggedInUser?.useraffiliation || "";
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [confirmLogoutModal, setConfirmLogoutModal] = useState(false);

  const handleProfileEdit = () => {
    setEditProfileModal(true);
  };

  function handleCloseEditProfileModal() {
    setEditProfileModal(false);
  }

  const handleConfirmLogout = () => {
    setConfirmLogoutModal(true);
  };

  const handleCloseConfirmLogout = () => {
    setConfirmLogoutModal(false);
  };
  
  const isAdmin = isUserAdmin();
  const link = {
    path: isAdmin ? "/adminprofile" : "/profile",
  }

  return (
    <>
      <div className="bc__logo-container">
        <Link to={link.path}><img className="bc__logo" src={BC_Logo} alt="BC_logo" /></Link>
      </div>
      <header className="header">
        <div className="profileheader">
          {currentLoggedInUserAvatar ? (
            <RenderLogo
              imageContent={currentLoggedInUser.imageContent}
              classes="profileheader__avatar"
            />
          ) : (
            <div className="profileheader__avatar ">
              <div className="profileheader__avatar-letter">
                {currentLoggedInUserFirstName[0]?.toLowerCase()}
                {currentLoggedInUserLastName[0]?.toLowerCase()}
              </div>
            </div>
          )}
          <p className="profileheader__username">
            {currentLoggedInUserFirstName} {currentLoggedInUserLastName}
          </p>
          <p className="profileheader__useraffiliation">
            {currentLoggedInUserAffiliation}
          </p>
          <div className="profileheader__buttons">
            <button
              className="button button-secondary"
              type="button"
              onClick={handleConfirmLogout}
            >
              LOGOUT
            </button>
            <button
              className="button button-secondary"
              type="button"
              onClick={handleProfileEdit}
            >
              EDIT PROFILE
            </button>
          </div>
        </div>
        {editProfileModal && (
          <EditProfileModal onClose={handleCloseEditProfileModal} />
        )}
        {confirmLogoutModal && (
          <LogoutModal onClose={handleCloseConfirmLogout} />
        )}
      </header>
    </>
  );
}

export default ProfileHeader;
