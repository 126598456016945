// import "./EventPage.css";

import DiscussionBoardHeader from "../DiscussionBoardHeader/DiscussionBoardHeader";
import DiscussionBoardPageMain from "../DiscussionBoardPageMain/DiscussionBoardPageMain";
import { isUserAdmin } from "../../utils/auth";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  getAggregatePoll,
  getEventAndEventTopicByDiscussionQuestionId,
  getPolling,
  hasUserPolled,
} from "../../utils/api";

function DiscussionBoardPage() {
  const isAdmin = isUserAdmin();
  const params = useParams();
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [currentTopic, setCurrentTopic] = useState({});
  const [currentDiscussionQuestion, setCurrentDiscussionQuestion] = useState(
    {}
  );
  const [users, setUsers] = useState([]);
  const [discussionQuestion, setDiscussionQuestion] = useState({});
  const [
    currentDiscussionQuestionResponses,
    setCurrentDiscussionQuestionResponses,
  ] = useState([]);

  const [polling, setPolling] = useState([]);
  const [pollData, setPollData] = useState([]);
  const [hidePolling, setHidePolling] = useState(false);

  const handleLoadAttempt = (value) => {
    setLoadAttempt(value);
  };
  const handleResponses = (values) => {
    setCurrentDiscussionQuestionResponses((responses) => [
      ...responses,
      values,
    ]);
  };
  const getResponses = useCallback(() => {
    return getEventAndEventTopicByDiscussionQuestionId(params.id)
      .then((response) => {
        if (response) {
          setDiscussionQuestion(response);
          setCurrentEvent(response.event[0]);
          setCurrentTopic(response.eventtopics[0]);
          setCurrentDiscussionQuestion(response);
          setCurrentDiscussionQuestionResponses(
            response.eventtopicdiscussionquestionresponses
          );
          setUsers(response.users);
        }
      })
      .finally(() => setLoadAttempt(true));
  }, [params.id]);

  const getPoll = useCallback(() => {
    return getPolling(params.id)
      .then((response) => {
        if (response) {
          setPolling(response);
        }
        handlePollData();
      })
      .finally(() => setLoadAttempt(true));
  }, [params]);

  const handleUserHasPolled = () => {
    hasUserPolled(params.id).then((res) => {
      setHidePolling(res);
    });
  };

  const handlePollData = () => {
    getAggregatePoll(params.id).then((x) => {
      setPollData(x);
      handleUserHasPolled();
    });
  };

  useEffect(() => {
    if (!loadAttempt) {
      getResponses().then(() => getPoll());
    }
  });
  return (
    <>
      <DiscussionBoardHeader
        isAdmin={isAdmin}
        currentEvent={currentEvent}
        currentTopic={currentTopic}
        currentDiscussionQuestion={currentDiscussionQuestion}
        currentDiscussionQuestionResponses={currentDiscussionQuestionResponses}
        setLoadAttempt={handleLoadAttempt}
      />
      <DiscussionBoardPageMain
        isAdmin={isAdmin}
        currentEvent={currentEvent}
        currentTopic={currentTopic}
        currentDiscussionQuestion={currentDiscussionQuestion}
        currentDiscussionQuestionResponses={currentDiscussionQuestionResponses}
        discussionQuestion={discussionQuestion}
        users={users}
        setLoadAttempt={handleLoadAttempt}
        handleResponses={handleResponses}
        newResponseEmit={getResponses}
        polling={polling}
        handlePollData={handlePollData}
        pollData={pollData}
        hidePolling={hidePolling}
      />
    </>
  );
}

export default DiscussionBoardPage;
