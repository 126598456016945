import "./DiscussionBoardPageMain.css";
import ResponseCard from "../ResponseCard/ResponseCard";
import React, { useEffect, useState } from "react";
import AddResponseFilledIcon from "../../images/add_response-filled_whitebg.svg";
import { addDiscussionQuestionResponse } from "../../utils/api";
import { useParams } from "react-router-dom";
import CreateNewResponseModal from "../CreateNewResponseModal/CreateNewResponseModal";
import PollingOptionsComponent from "../PollingComponent/PollingComponent.js";
import BarChartComponent from "../BarChart/BarChartComponent.js";
import useSocket from "../../hooks/useSocket";

function DiscussionBoardPageMain({
  currentDiscussionQuestionResponses,
  setLoadAttempt,
  discussionQuestion,
  users,
  handleResponses,
  newResponseEmit,
  polling,
  pollData,
  hidePolling,
  handlePollData,
}) {
  const [createNewResponseModal, setCreateNewResponseModal] = useState(false);

  const { socket, setFetcher } = useSocket(setLoadAttempt);

  const params = useParams();

  const handleCreateNewResponse = () => {
    setCreateNewResponseModal(true);
  };

  const handleAddNewResponse = (values) => {
    addDiscussionQuestionResponse({
      questionid: params.id,
      eventdiscussionquestionresponses: values,
    })
      .then((res) => {
        socket.emit("room", params.id, res, newResponseEmit);
        handleResponses(res);
        setCreateNewResponseModal(false);
        setFetcher(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getUser = (response) => users.find((x) => x._id === response.userid[0]);
  const isPollingOpen = polling.length > 0 && polling[0].isPollingOpen || null
  useEffect(() => {
    handlePollData();
  }, []);

  return (
    <>
      <main className="main">
        {polling.length > 0 && (
          <div className="main__page-section">
            <div className="polling__info-container">
              <div></div>
              <h2 className="main__headers polling__subheader1-container">
                Poll
              </h2>
              <p className="card__text-status polling__subheader1-container">
                {isPollingOpen ? 'active': 'closed' }
              </p>
            </div>
            <section className="main__card-section" id="card-section">
              {polling?.map((response) => {
                console.log(response);
                return (
                  <>
                    {hidePolling ? (
                      <BarChartComponent key={response._id} data={pollData} />
                    ) : (
                      <div className="polling__info-container">
                        <div>
                          <PollingOptionsComponent
                            key={response._id}
                            data={response}
                            handlePollData={handlePollData}
                            hidePolling={hidePolling}
                            setFetcher={setFetcher}
                            reload={() => setLoadAttempt(false)}
                            socket={socket}
                          />
                        </div>
                        {/* {isPollingOpen && (
                          <div>
                            <button
                              type="button"
                              className="button-icon button-icon-outline"
                              onClick={handleClosePoll}
                            >
                              <img
                                className="icon-enlarge"
                                src={closeStatusIcon}
                                alt="close event"
                              />
                            </button>
                          </div>
                        )} */}
                      </div>
                    )}
                  </>
                );
              })}
            </section>
          </div>
        )}
        <div className="main__page-section">
          <h2 className="main__headers">Responses</h2>
          <button
            type="button"
            className="button button-stretch"
            onClick={handleCreateNewResponse}
          >
            CREATE NEW RESPONSE
          </button>
          <section className="main__card-section" id="card-section">
            {currentDiscussionQuestionResponses?.map((response) => {
              if (response !== null) {
                let user = getUser(response) || {};
                return (
                  <ResponseCard
                    key={response._id}
                    item={response}
                    reload={() => setLoadAttempt(false)}
                    discussionQuestion={discussionQuestion}
                    responseUser={user}
                    socket={socket}
                    setFetcher={setFetcher}
                    newResponseEmit={newResponseEmit}
                  />
                );
              }
            })}
            <div className="button-floating">
              <button
                className="button-icon"
                type="button"
                onClick={handleCreateNewResponse}
              >
                <img
                  className="icon-max"
                  src={AddResponseFilledIcon}
                  alt="add response icon"
                />
              </button>
            </div>
          </section>
        </div>
      </main>
      {createNewResponseModal && (
        <CreateNewResponseModal
          onAddResponse={handleAddNewResponse}
          onClose={() => {
            setCreateNewResponseModal(false);
          }}
          switchToThoughtQuestionsModal={() => {
            setCreateNewResponseModal(false);
          }}
        />
      )}
    </>
  );
}

export default DiscussionBoardPageMain;
