import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

const EditTopicModal = ({ isOpen, onClose, onEditTopic, currentTopic }) => {
  const currentTopicName = currentTopic?.topicname;
  const currentTopicDescription = currentTopic?.topicdescription;
  const currentTopicQuestions = currentTopic?.topicquestions;
  const currentTopicResources = currentTopic?.topicresources;

  const [topicName, setTopicName] = useState("");
  const [topicDescription, setTopicDescription] = useState("");

  useEffect(() => {
    setTopicName(currentTopicName);
    setTopicDescription(currentTopicDescription);
  }, [
    currentTopicName,
    currentTopicDescription,
    currentTopicQuestions,
    currentTopicResources,
  ]);

  function handleTopicNameChange(evt) {
    setTopicName(evt.target.value);
  }

  function handleTopicDescriptionChange(evt) {
    setTopicDescription(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const update_topic = {};
    update_topic.topicname = topicName;
    update_topic.topicdescription = topicDescription;
    onEditTopic(update_topic);
  }

  return (
    <ModalWithForm
      buttonText="Save Topic Changes"
      title="Edit Topic"
      onClose={onClose}
      name="new-event-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Topic Name
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          maxLength="128"
          placeholder="Name"
          value={topicName}
          onChange={handleTopicNameChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-imagelabel">
        Topic Description
        <textarea
          className="modal__input"
          id="modal-name"
          type="text"
          name="topicdescription"
          placeholder="Description"
          value={topicDescription}
          onChange={handleTopicDescriptionChange}
          required
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};
export default EditTopicModal;
