import { checkResponse } from "./api";
import jwtDecode from "jwt-decode";
import { ADMIN } from "./constants";

//BaseUrl for Backend Local Dev Environment
export const baseUrl = `${
  `${process.env.REACT_APP_ENV}/api` || "http://localhost:4000/api"
}`;
// export const baseUrl = "http://localhost:4000/api";
///TODO: https://stackoverflow.com/questions/22669528/securely-storing-environment-variables-in-gae-with-app-yaml
// /signin for user authorization
export const signIn = (useremail, userpassword) => {
  return fetch(`${baseUrl}/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ useremail, userpassword }),
  }).then((res) => checkResponse(res));
};

// POST Add New User to database
export const addNewUser = ({
  useremail,
  userpassword,
  firstName,
  lastName,
  useraffiliation,
  userrole,
  useravatar,
  userinvitedevents,
  userstatus,
}) => {
  return fetch(`${baseUrl}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      useremail,
      userpassword,
      firstName,
      lastName,
      useraffiliation,
      userrole,
      useravatar,
      userinvitedevents,
      userstatus,
    }),
  }).then((res) => checkResponse(res));
};

export function getToken() {
  return `Bearer ${localStorage.getItem("jwt")}`;
}

export function getDecodedToken() {
  const token = getToken();
  if (token !== null && localStorage.getItem("jwt") !== null) {
    return jwtDecode(token);
  }
  return null;
}

export function getCurrentUserRole() {
  const token = getDecodedToken();
  return token ? token.role : null;
}

export function getCurrentUser() {
  const token = getDecodedToken();
  return token ? token._id : null;
}

export function isUserAdmin() {
  const token = getDecodedToken();
  return token ? token.role === ADMIN : null;
}

export const getBase64File = async (file) => {
  console.log(file, "file");
  if (file === null || file === "") {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => resolve(reader.result));
  });
};
// /user/me for token check
export const checkToken = () => {
  return fetch(`${baseUrl}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
