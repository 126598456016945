import "./HomePage.css";
import React, { useEffect, useState } from "react";
import BC_Logo from "../../images/BC_Logo.svg";
import { INVALID_LOGIN } from "../../utils/constants";
import ForgotPasswordModal from "./ForgotPasswordModal";

function HomePage({ onUserLogin }) {
  const [userLoginEmail, setUserLoginEmail] = useState("");
  const [userLoginPassword, setUserLoginPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [error, showError] = useState(null);
  const [showPasswordModal, setPasswordModal] = useState(false);

  useEffect(() => {
    setUserLoginEmail("");
    setUserLoginPassword("");
  }, []);

  function handleUserLoginEmailChange(evt) {
    showError(false);
    setUserLoginEmail(evt.target.value);
  }

  function handleUserLoginPasswordChange(evt) {
    showError(false);
    setUserLoginPassword(evt.target.value);
  }

  function handleChecked() {
    showError(false);
    setChecked(!checked);
  }

  const handleForgotPassword = () => {
    setPasswordModal(true);
  };

  function handleSubmit(evt) {
    evt.preventDefault();
    if (userLoginEmail !== "" && userLoginPassword !== "" && checked) {
      const userLogin = {};
      userLogin.useremail = userLoginEmail;
      userLogin.userpassword = userLoginPassword;
      onUserLogin(userLogin).then((res) => {
        console.log(res);
        if (res === INVALID_LOGIN) {
          showError(INVALID_LOGIN);
        }
      });
    } else {
      showError("All Fields are required");
    }
  }

  return (
    <div className="pre-homepage">
      <img className="homepage__logo" src={BC_Logo} alt="Logo" />
      <div className="homepage container">
        <form className="homepage__login-form">
          <div className="card__text-title ">Sign in to your Account</div>
          <div className="homepage__form-inputs">
            <label className="modal__label" id="modal-emaillabel">
              <input
                autoFocus
                className="modal__input"
                id="modal-email"
                type="email"
                name="email"
                placeholder="Email"
                value={userLoginEmail}
                onChange={handleUserLoginEmailChange}
                required
              />
            </label>
            <span className="modal__error" id="modal-email-error"></span>
            <label className="modal__label" id="modal-passwordlabel">
              <input
                className="modal__input"
                id="modal-password"
                type="password"
                name="password"
                placeholder="Password"
                minLength={8}
                value={userLoginPassword}
                onChange={handleUserLoginPasswordChange}
                required
              />
            </label>
          </div>
          <div className="card__text">
            <input type="checkbox" checked={checked} onChange={handleChecked} />
            I agree to the terms and conditions
          </div>
          {error && (
            <span className="modal__error-login" id="modal-link-error">
              {error}
            </span>
          )}
          <button className="button" type="button" onClick={handleSubmit}>
            Sign in
          </button>
        </form>
        <button
          className="button-no_outline"
          type="button"
          onClick={handleForgotPassword}
        >
          Forgot Password
        </button>
      </div>
      {showPasswordModal && (
        <ForgotPasswordModal setPasswordModal={setPasswordModal} />
      )}
    </div>
  );
}

export default HomePage;
