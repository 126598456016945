import React from "react";
import { TYPE_MAP } from "../ConfirmCloseEventModal/ConfirmCloseModal";

const ConfirmLaunchModal = ({ handleLaunch, onClose, type }) => {
  return (
    <div className="modal">
      <div className="modal__content">
        <p className="modal__confirm-message">
          Are you sure you want to launch this {TYPE_MAP[type]}?
        </p>
        <p className="modal__confirm-message">
          <button className="button button-error" onClick={handleLaunch}>
            Yes, launch {TYPE_MAP[type]}
          </button>
        </p>
        <p className="modal__confirm-cancel" onClick={onClose}>
          Cancel
        </p>
      </div>
    </div>
  );
};

export default ConfirmLaunchModal;
