import "./EventPage.css";

import React, { useCallback, useContext, useEffect, useState } from "react";

import EventPageHeader from "../EventPageHeader/EventPageHeader";

import { useParams } from "react-router-dom";
import { getEvent } from "../../utils/api";
import CurrentEventContext from "../../contexts/CurrentEventContext";
import EventPageMain from "../EventPageMain/EventPageMain";

function EventPage({
  onSelectCard,
  handleEditEvent,
  handleCreateNewTopic,
  handleTopicManagmentModal,
  onSelectTopicCard,
  handleAddEventResourceForm,
  onSelectEventResourceCard,
  onOpenEventResourceDeleteModal,
  handleTopicResources,
  handleInviteUsers,
  handleUserInvites,
  handleUserEventAdd,
  handleOpenRemoveEventFromUserProfileModal,
  setLaunchState,
  handleDeleteTopic,
  handleEventWelcomeVideo,
}) {
  const { handleAddEventToContext } = useContext(CurrentEventContext);
  const [event, setEvent] = useState({});
  const [loadAttempt, setLoadAttempt] = useState(false);
  const params = useParams();

  const eventId = params.eventId;
  const handleGetEvent = useCallback(() => {
    return getEvent({ id: eventId }).then((updatedEventCard) => {
      setEvent(updatedEventCard);
      return Promise.resolve(updatedEventCard);
    });
  }, [params, setEvent]);

  const reloadEvent = useCallback(() => {
    setLoadAttempt(false);
  }, []);
  useEffect(() => {
    if (!loadAttempt && eventId) {
      getEvent({ id: eventId }).then((eventResponse) => {
        setEvent(eventResponse);
        setLoadAttempt(true);
        handleAddEventToContext(params.eventId);
      });
    }
  }, [handleAddEventToContext, loadAttempt, eventId]);
  console.log(event);
  return (
    <>
      <EventPageHeader event={event} reloadEvent={reloadEvent} />
      <EventPageMain
        event={event}
        handleEditEvent={handleEditEvent}
        onSelectCard={onSelectCard}
        handleCreateNewTopic={handleCreateNewTopic}
        handleTopicManagmentModal={handleTopicManagmentModal}
        onSelectTopicCard={onSelectTopicCard}
        handleAddEventResourceForm={handleAddEventResourceForm}
        onSelectEventResourceCard={onSelectEventResourceCard}
        onOpenEventResourceDeleteModal={onOpenEventResourceDeleteModal}
        handleTopicResources={handleTopicResources}
        handleInviteUsers={handleInviteUsers}
        handleUserInvites={handleUserInvites}
        handleUserEventAdd={handleUserEventAdd}
        handleOpenRemoveEventFromUserProfileModal={
          handleOpenRemoveEventFromUserProfileModal
        }
        setLaunchState={setLaunchState}
        handleDeleteTopic={handleDeleteTopic}
      />
    </>
  );
}

export default EventPage;
