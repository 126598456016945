import ModalWithForm from "../ModalWithForm/ModalWithForm";
import React, { useEffect, useState } from "react";
import { sendForgotPassword } from "../../utils/api";

const ForgotPasswordModal = ({ setPasswordModal }) => {
  const [email, setEmail] = useState("");
  const handleUserLoginEmailChange = (evt) => {
    setEmail(evt.target.value);
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    sendForgotPassword(email)
      .then((r) => {
        setPasswordModal(false);
      })
      .catch(() => {})
      .finally(() => {
        setPasswordModal(false);
        setEmail("");
      });
  };

  useEffect(() => {
    return () => setEmail("");
  }, []);
  return (
    <>
      <ModalWithForm
        name="Forgot Password"
        buttonText="Send Email"
        onSubmit={handleSubmit}
        onClose={() => {
          setPasswordModal(false);
        }}
      >
        <div className="card__text-title ">Please enter your email</div>
        <label className="modal__label" id="modal-emaillabel">
          <input
            autoFocus
            className="modal__input"
            id="modal-email"
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleUserLoginEmailChange}
            required
          />
        </label>
      </ModalWithForm>
    </>
  );
};
export default ForgotPasswordModal;
