import "./EventPageHeader.css";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import BC_Logo from "../../images/BC_Logo.svg";
import Navbar from "../Navbar/Navbar";
import VideoIcon from "../../images/video_file.svg";
import EditEventModal from "../EditEventModal/EditEventModal";
import React, { useState } from "react";
import { editEventInfo } from "../../utils/api";
import EventActionButtons from "../EventActionButtons/EventActionButtons";
import { isUserAdmin } from "../../utils/auth";
import RenderLogo from "../RenderLogo";
import { NewTab } from "../TopicResourceCard/TopicResourceCard";

function EventPageHeader({ event, reloadEvent }) {
  const isAdmin = isUserAdmin();
  const [editEventModal, setEditEventModal] = useState(false);
  const currentEventName = event?.eventname;
  const currentEventStartDate = event?.eventstartdate;
  const currentEventEndDate = event?.eventenddate;
  const currentEventWelcome = event?.eventwelcomemessage;
  const currentEventWelcomeVideo = event?.eventwelcomevideo;
  const currentEventAgenda = event?.eventagenda;
  const currentEventLaunchState = event?.eventlaunchstate;

  const event_startdate = dayjs(currentEventStartDate).format("MM-DD-YYYY");
  const event_enddate = dayjs(currentEventEndDate).format("MM-DD-YYYY");
  const link = {
    text: "Profile",
    path: isAdmin ? "/adminprofile" : "/profile",
  };

  const openEditEventModal = () => {
    setEditEventModal(true);
  };

  const handleEditEventSubmit = ({
    eventname,
    eventstartdate,
    eventenddate,
    eventwelcomemessage,
    eventagenda,
    eventlogo,
  }) => {
    editEventInfo(event._id, {
      eventname,
      eventstartdate,
      eventenddate,
      eventwelcomemessage,
      eventagenda,
      eventlogo,
    })
      .then(() => {
        setEditEventModal(false);
        reloadEvent();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="bc__logo-container">
        <Link to={link.path}><img className="bc__logo" src={BC_Logo} alt="BC_logo" /></Link>
        <Navbar link={link} />
      </div>
      <header className="pageheader">
        <div className="pageheader__info-container">
          <div className="pageheader__subheader1-container">
            <div className="pageheader__subheader2-container">
              <p className="card__text-title"> Event </p>
              <p className="card__text-date">{currentEventName}</p>
              <p className="card__text-title">Event Dates</p>
              <p className="card__text-date">
                {event_startdate} TO {event_enddate}
              </p>
            </div>
            <div className="pageheader__statusicons-container">
              <RenderLogo
                imageContent={event?.imageContent}
                classes="pageheader__event-logo"
              />
              {isAdmin && (
                <p className="card__text-status"> {currentEventLaunchState}</p>
              )}
            </div>
          </div>
          <div className="pageheader__line"></div>
          <div className="pageheader__info_container">
            <div className="pageheader__info-container-row">
              <div className="pageheader__info-container-col">
                <p className="card__text-title">Welcome Message</p>
                <p className="card__text-date">{currentEventWelcome}</p>
              </div>
              <div>
                {" "}
                {currentEventWelcomeVideo !== "" ? (
                  <div className="pageheader__icon-container">
                    <NewTab
                      videoinfo={currentEventWelcomeVideo}
                      src={VideoIcon}
                    />{" "}
                  </div>
                ) : (
                  <></>
                )}
                {isAdmin && (
                  <EventActionButtons
                    openModal={openEditEventModal}
                    id={event._id}
                    state={event.eventlaunchstate}
                    type="event"
                    reload={reloadEvent}
                  />
                )}
              </div>
            </div>
            <div className="pageheader__info-container-row">
              <div className="pageheader__info-container-col">
                {/* <p className="card__text-title">Agenda</p>
                <p className="card__text-date">{currentEventAgenda}</p> */}
              </div>
              {/* <div>
                {isAdmin && (
                  <EventActionButtons
                    openModal={openEditEventModal}
                    id={event._id}
                    state={event.eventlaunchstate}
                    type="event"
                    reload={reloadEvent}
                  />
                )}
              </div> */}
            </div>
          </div>
        </div>
      </header>
      {editEventModal && (
        <EditEventModal
          currentEvent={event}
          onEditEvent={handleEditEventSubmit}
          onClose={() => {
            setEditEventModal(false);
          }}
        />
      )}
    </>
  );
}

export default EventPageHeader;
