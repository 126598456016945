import React, { useContext, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import CurrentEventContext from "../../contexts/CurrentEventContext";
import CurrentTopicContext from "../../contexts/CurrentTopicContext";

const CreateNewTopicResourceModal = ({ isOpen, onAddItem, onClose }) => {
  const currentEvent = useContext(CurrentEventContext);
  const currentTopic = useContext(CurrentTopicContext);
  const [topicResourceName, setTopicResourceName] = useState("");
  const [topicResourceFile, setTopicResourceFile] = useState("");
  const [topicResourceVideo, setTopicResourceVideo] = useState("");

  useEffect(() => {
    setTopicResourceName("");
    setTopicResourceFile("");
    setTopicResourceVideo("");
  }, [isOpen]);

  function handleTopicResourceName(evt) {
    setTopicResourceName(evt.target.value);
  }

  function handleTopicResourceFile(evt) {
    const acceptedFile = evt.target.files;
    const file = acceptedFile.length === 1 && acceptedFile[0];
    setTopicResourceFile(
      file &&
        Object.assign(file, {
          url: URL.createObjectURL(file),
        })
    );
  }

  function handleTopicResourceVideo(evt) {
    setTopicResourceVideo(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const topicresource = {};
    topicresource.eventtopicresourcename = topicResourceName;
    topicresource.eventtopicresourcepdf = topicResourceFile;
    topicresource.eventtopicresourcevideo = topicResourceVideo;
    onAddItem(topicresource, currentEvent, currentTopic);
  }

  return (
    <ModalWithForm
      buttonText="Create New Topic Resource"
      title="Create New Topic Resource"
      onClose={onClose}
      name="new-topic-resource"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Topic Resource Name
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Topic Resource Name"
          value={topicResourceName}
          onChange={handleTopicResourceName}
          required
        />
      </label>
      <p className="modal__subheading">Add a PDF or Video Topic Resource</p>
      <div className="modal__resources">
        <label className="modal__label-align modal__label" id="modal-namelabel">
          Topic Resource Video
          <input
            className="modal__input"
            id="modal-name"
            type="text"
            name="welcomevideo"
            minLength="1"
            placeholder="Topic Resource Video URL"
            value={topicResourceVideo}
            onChange={handleTopicResourceVideo}
            disabled={topicResourceFile.length > 0}
          />
        </label>
        <label
          className="modal__label-align modal__label"
          id="modal-imagelabel"
        >
          Topic Resource File (.pdf)
          <input
            className="modal__input file-input"
            type="file"
            accept="application/pdf"
            name="event_resourcefile"
            placeholder="File"
            onChange={handleTopicResourceFile}
            disabled={topicResourceVideo.length > 0}
          />
        </label>
      </div>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default CreateNewTopicResourceModal;
