import React from "react";

const ConfirmPollingResponseModal = ({ onClose }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Your polling selection has been submitted
      </p>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Close
      </p>
    </div>
  );
};

export default ConfirmPollingResponseModal;
