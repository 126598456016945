// import LogoutModal from "../LogoutModal/LogoutModal";
import React from "react";

const DeleteTopicResourceModal = ({ handleTopicResourceDelete, onClose }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to delete this topic resource?
      </p>
      <button
        className="button button-error"
        onClick={handleTopicResourceDelete}
      >
        Yes, delete topic resource
      </button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default DeleteTopicResourceModal;
