export const ADMIN = "admin";
export const USER = "user";

export const PRELAUNCH = "prelaunch";
export const PENDING_RSVP = "pendingRsvp";

export const ACTIVE = "active";
export const CLOSED = "closed";

export const INVALID_LOGIN = "Incorrect email or password";
