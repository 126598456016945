import React, { useContext, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import { editProfileInfo } from "../../utils/api";

const EditProfileModal = ({ onClose }) => {
  const context = useContext(CurrentLoggedInUserContext);
  const currentLoggedInUserAffiliation =
    context.currentLoggedInUser?.useraffiliation;
  const currentLoggedInUserAvatar = context.currentLoggedInUser?.useravatar;
  const currentLoggedInUserEmail = context.currentLoggedInUser?.useremail;

  const [userAffiliation, setUserAffiliation] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userAvatar, setUserAvatar] = useState("");

  useEffect(() => {
    setUserAffiliation(currentLoggedInUserAffiliation);
    setUserEmail(currentLoggedInUserEmail);
    setUserAvatar(currentLoggedInUserAvatar);
  }, [
    currentLoggedInUserAffiliation,
    currentLoggedInUserEmail,
    currentLoggedInUserAvatar,
  ]);

  function handleUserAffiliationChange(evt) {
    setUserAffiliation(evt.target.value);
  }

  const handleEditProfileInfo = ({ useraffiliation }) => {
    editProfileInfo({
      id: context.currentLoggedInUser._id,
      useraffiliation,
    })
      .then((res) => {
        console.log(res);
        context.setCurrentLoggedInUser(res);
        onClose();
      })
      .catch((err) => {
        console.log(err.err);
      });
  };

  function handleUserAvatarChange(evt) {
    const acceptedFile = evt.target.files;
    const file = acceptedFile.length === 1 && acceptedFile[0];
    setUserAvatar(
      file &&
        Object.assign(file, {
          url: URL.createObjectURL(file),
        })
    );
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const update_user = {};
    update_user.useraffiliation = userAffiliation;
    update_user.useravatar = userAvatar;
    handleEditProfileInfo(update_user);
  }

  return (
    <ModalWithForm
      buttonText="Save Profile Changes"
      title="Edit Profile"
      onClose={onClose}
      name="user-profile-edit"
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Affiliation
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          maxLength="64"
          placeholder="Name"
          value={userAffiliation}
          onChange={handleUserAffiliationChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        Email address
        <input
          className="modal__input"
          id="modal-name"
          type="email"
          name="name"
          placeholder="Email"
          value={userEmail}
          disabled
        />
      </label>
      {/* <label className="modal__label" id="modal-imagelabel">
        Avatar (.png/.jpeg)
        <input
          className="modal__input file-input"
          id="modal-link"
          type="file"
          accept="image/png, image/jpeg"
          name="link"
          placeholder="Avatar Image"
          onChange={handleUserAvatarChange}
        />
      </label> */}
    </ModalWithForm>
  );
};

export default EditProfileModal;
