import React, { useContext, useEffect, useState } from "react";
import { getCurrentUserRole } from "../../utils/auth";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import CurrentDiscussionQuestionContext from "../../contexts/CurrentDiscussionQuestionContext";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";

// onAddItem refers to handleAddItemSubmit, which is declared in App.js
const CreateNewResponseModal = ({ isOpen, onAddResponse, onClose }) => {
  const currentDiscussionQuestion = useContext(
    CurrentDiscussionQuestionContext
  );

  const currentUser = useContext(CurrentLoggedInUserContext);
  console.log(currentUser?.currentLoggedInUser);
  const adminoruser = getCurrentUserRole();

  // declare state for each input field (name, imageUrl, weather)
  const [responseText, setResponseText] = useState("");
  const [responderUsername, setResponderUsername] = useState("");
  const [responderAffil, setResponderAffil] = useState("");
  const [responderAvatar, setResponderAvatar] = useState("");
  const [responseLikes, setResponseLikes] = useState([]);
  const [responseDislikes, setResponseDislikes] = useState([]);

  useEffect(() => {
    setResponseText("");
    setResponderUsername("");
    setResponderAffil("");
    setResponderAvatar("");
    setResponseLikes([]);
    setResponseDislikes([]);
  }, [isOpen]);

  function handleResponseTextChange(evt) {
    setResponseText(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const responseinfo = {};
    responseinfo.discussionquestionresponse = responseText;
    responseinfo.responderusername = responderUsername;
    responseinfo.responderaffiliation = responderAffil;
    responseinfo.responderavatar = responderAvatar;
    responseinfo.likes = responseLikes;
    responseinfo.dislikes = responseDislikes;
    onAddResponse(responseinfo, currentDiscussionQuestion);
  }

  return (
    <ModalWithForm
      buttonText="Post New Response"
      title="Create New Response"
      onClose={onClose}
      name="new-event-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      {adminoruser === "admin" ? (
        <>
          <div className="modal__label">
            <label className="modal__label" id="modal-namelabel">
              <button
                className="button button-outline"
                type="button"
                value="That's an interesting point. Does anyone have anything more to add on
          this point?"
                onClick={handleResponseTextChange}
              >
                That's an interesting point. Does anyone have anything more to
                add on this point?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="Great point - Do others agree or disagree and why?"
                onClick={handleResponseTextChange}
              >
                Great point - Do others agree or disagree and why?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="Thank you for your insight - What information that you can share
          supports this point?"
                onClick={handleResponseTextChange}
              >
                Thank you for your insight - What information that you can share
                supports this point?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="Thank you for your insight - What data, if any, might conflict with
          this point?"
                onClick={handleResponseTextChange}
              >
                Thank you for your insight - What data, if any, might conflict
                with this point?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="Thank you for your comment - How does this impact your decision
          process?"
                onClick={handleResponseTextChange}
              >
                Thank you for your comment - How does this impact your decision
                process?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="That is an interesting point - can you expand on this?"
                onClick={handleResponseTextChange}
              >
                That is an interesting point - can you expand on this?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="That's a great idea - Who is the right audience for this?"
                onClick={handleResponseTextChange}
              >
                That's a great idea - Who is the right audience for this?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="That's a great idea - How would you recommend doing this?"
                onClick={handleResponseTextChange}
              >
                That's a great idea - How would you recommend doing this?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="Thank you for the robust discussion - Does anyone have anything more
          to add?"
                onClick={handleResponseTextChange}
              >
                Thank you for the robust discussion - Does anyone have anything
                more to add?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="What do you all think is NOT being considered now that should be?"
                onClick={handleResponseTextChange}
              >
                What do you all think is NOT being considered now that should
                be?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="What is your one key recommendation for our company from this
          discussion?"
                onClick={handleResponseTextChange}
              >
                What is your one key recommendation for our company from this
                discussion?
              </button>
              <button
                className="button button-outline"
                type="button"
                value="To understand concensus, on a scale of 1-5 with 1 representing
          'Strongly disagree' and 5 representing 'Strongly agree', how
          strongly do you disagree or agree with this comment?"
                onClick={handleResponseTextChange}
              >
                To understand concensus, on a scale of 1-5 with 1 representing
                "Strongly disagree" and 5 representing "Strongly agree", how
                strongly do you disagree or agree with this comment?
              </button>
            </label>
          </div>
          <label className="modal__label" id="modal-namelabel">
            Response
            <textarea
              autoFocus
              className="modal__input textarea"
              id="modal-name"
              type="text"
              name="name"
              minLength="1"
              placeholder="Response"
              value={responseText}
              onChange={handleResponseTextChange}
              required
            />
          </label>
        </>
      ) : (
        <>
          {" "}
          <label className="modal__label" id="modal-namelabel">
            Response
            <textarea
              autoFocus
              className="modal__input textarea"
              id="modal-name"
              type="text"
              name="name"
              minLength="1"
              placeholder="Response"
              value={responseText}
              onChange={handleResponseTextChange}
              required
            />
          </label>
        </>
      )}

      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default CreateNewResponseModal;
