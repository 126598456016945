import "./AdminTopicPageMain.css";
import { useCallback, useEffect, useState } from "react";
import DiscussionQuestionCard from "../DiscussionQuestionCard/DiscussionQuestionCard";
import TopicResourceCard from "../TopicResourceCard/TopicResourceCard";
import {
  addEventTopicDiscussionQuestion,
  addEventTopicResource,
  getEventTopic,
  getTopicDiscussionQuestions,
  getTopicResources,
} from "../../utils/api";
import { useParams } from "react-router-dom";
import CreateNewDiscussionQuestionModal from "../CreateNewDiscussionQuestionModal/CreateNewDiscussionQuestionModal";
import CreateNewTopicResourceModal from "../CreateNewTopicResourceModal/CreateNewTopicResourceModal";

function AdminTopicPageMain() {
  const params = useParams();
  const [newTopicResourceModal, setNewTopicResourceModal] = useState(false);
  const [newDiscussionQuestionModal, setNewDiscussionQuestionModal] =
    useState(false);
  const [topicResources, setTopicResources] = useState([]);
  const [topic, setTopic] = useState({});
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [discussionQuestions, setDiscussionQuestions] = useState([]);

  useEffect(() => {
    if (!loadAttempt) {
      getEventTopic(params.id)
        .then((topicResponse) => {
          setTopic(topicResponse);
          getTopicResources({
            topicidarray: topicResponse.topicresources,
          }).then((topicResourcesResponse) => {
            setTopicResources(topicResourcesResponse);
          });
          getTopicDiscussionQuestions({
            topicidarray: topicResponse.topicquestions,
          }).then((discussionResponse) => {
            setDiscussionQuestions(discussionResponse);
          });
        })
        .finally(() => {
          setLoadAttempt(true);
        });
    }
  }, [loadAttempt, params.id]);

  const openNewDiscussionQuestionModal = () => {
    setNewDiscussionQuestionModal(true);
  };

  const reload = useCallback(() => {
    setLoadAttempt(false);
  }, []);

  const handleAddNewDiscussionQuestion = (values) => {
    addEventTopicDiscussionQuestion({
      topicid: params.id,
      eventtopicdiscussionquestions: values,
    })
      .then(() => {
        setLoadAttempt(false);
        setNewDiscussionQuestionModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openCreateNewTopicResourceModal = () => {
    setNewTopicResourceModal(true);
  };

  const handleAddNewTopicResource = (values) => {
    addEventTopicResource({
      topicid: topic._id,
      eventtopicresources: values,
    })
      .then(() => {
        setNewTopicResourceModal(false);
        setLoadAttempt(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <main className="main">
        <>
          <div className="main__page-section">
            <h2 className="main__headers">Topic Resources</h2>
            <button
              type="button"
              className="button button-stretch"
              onClick={openCreateNewTopicResourceModal}
            >
              CREATE NEW TOPIC RESOURCE
            </button>
            <section className="main__card-section" id="card-section">
              {topicResources?.map((resource) => (
                <TopicResourceCard
                  key={resource._id}
                  item={resource}
                  topic={topic}
                  reload={reload}
                />
              ))}
            </section>
          </div>
        </>
        <>
          <div className="main__page-section">
            <h2 className="main__headers">Topic Discussion Questions</h2>
            <button
              onClick={openNewDiscussionQuestionModal}
              type="button"
              className="button button-stretch"
            >
              CREATE NEW DISCUSSION QUESTION
            </button>
            <section className="main__card-section" id="card-section">
              {discussionQuestions?.map((question) => (
                <DiscussionQuestionCard
                  key={question._id}
                  questioninfo={question}
                  topic={topic}
                  reload={reload}
                />
              ))}
            </section>
          </div>
        </>
      </main>
      {newDiscussionQuestionModal && (
        <CreateNewDiscussionQuestionModal
          onAddDiscussionQuestion={handleAddNewDiscussionQuestion}
          onClose={() => {
            setNewDiscussionQuestionModal(false);
          }}
        />
      )}
      {newTopicResourceModal && (
        <CreateNewTopicResourceModal
          onAddItem={handleAddNewTopicResource}
          switchToAddEventLevelResource={() => {
            setNewTopicResourceModal(false);
          }}
          onClose={() => {
            setNewTopicResourceModal(false);
          }}
        />
      )}
    </>
  );
}

export default AdminTopicPageMain;
