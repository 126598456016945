import "./EventPageMain.css";
import React, { useCallback, useEffect, useState } from "react";

import TopicCard from "../TopicCard/TopicCard";
import EventResourceCard from "../EventResourceCard/EventResourceCard";
import { getEvent, getEventResources, getEventTopics } from "../../utils/api";
import { useParams } from "react-router-dom";

function EventPageMain({
  event,
  onSelectEventResourceCard,
  onOpenEventResourceDeleteModal,
}) {
  const [currentEventTopics, setCurrentEventTopic] = useState([]);
  const [selectedEventResources, setSelectedEventResources] = useState([]);
  const [loadAttempt, setLoadAttempt] = useState(false);

  const params = useParams();

  const reload = useCallback(() => {
    setLoadAttempt(false);
  }, []);

  useEffect(() => {
    if (params.eventId && !loadAttempt) {
      getEvent({ id: params.eventId })
        .then((event) => {
          let eventTopics = event.eventtopics;
          getEventTopics({ eventidarray: eventTopics }).then((eventTopics) => {
            setCurrentEventTopic(eventTopics);
          });
          getEventResources({ eventidarray: event.eventresources }).then(
            (eventResources) => {
              setSelectedEventResources(eventResources);
            },
          );
        })
        .finally(() => {
          setLoadAttempt(true);
        });
    }
  }, [params, currentEventTopics, loadAttempt]);
  return (
    <main className="main">
      <>
        <div className="main__page-section">
          <h2 className="main__headers">Event Resources</h2>
          <section className="main__card-section" id="card-section">
            {selectedEventResources?.map((resource) => (
              <EventResourceCard
                key={resource._id}
                item={resource}
                onSelectEventResourceCard={onSelectEventResourceCard}
                onOpenEventResourceDeleteModal={onOpenEventResourceDeleteModal}
                event={event}
                reload={reload}
              />
            ))}
          </section>
        </div>
      </>
      <>
        <div className="main__page-section">
          <h2 className="main__headers">Event Topics</h2>
          <section className="main__card-section" id="card-section">
            {currentEventTopics?.map((topic) => (
              <TopicCard
                key={topic._id}
                topic={topic}
                event={event}
                reload={reload}
              />
            ))}
          </section>
        </div>
      </>
    </main>
  );
}

export default EventPageMain;
