import "./ModalWithForm.css";
import React from "react";

const ModalWithForm = ({
  children,
  buttonText,
  title,
  onClose,
  name,
  onSubmit,
}) => {
  return (
    <div className={`modal modal_type_${name}`}>
      <div className="modal__content">
        <button
          className="modal__close"
          onClick={onClose}
          type="button"
        ></button>
        <h3 className="modal__heading">{title}</h3>
        <form className="modal__form" name={name} onSubmit={onSubmit}>
          {children}
          <div className="modal__buttons">
            {buttonText.length > 0 && (
              <button className="button" type="submit">
                {buttonText}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalWithForm;
