//Item Card Component
import React, { useCallback, useContext, useState } from "react";
import dayjs from "dayjs";
import "./ResponseCard.css";
import EditIcon from "../../images/EditIcon.svg";
import DeleteIcon from "../../images/DeleteIcon.svg";
import ReplyIcon from "../../images/ReplyIcon.svg";
import ExpandIcon from "../../images/ExpandIcon.svg";
import DisabledExpandIcon from "../../images/DisabledExpandIcon.svg";
import LikeInactiveIcon from "../../images/like-inactive.svg";
import LikeActiveIcon from "../../images/like-active.svg";
import DislikeInactiveIcon from "../../images/dislike-inactive.svg";
import DislikeActiveIcon from "../../images/dislike-active.svg";
import { getCurrentUser, getCurrentUserRole } from "../../utils/auth";
import EditResponseModal from "../EditResponseModal/EditResponseModal";
import CreateNewReplyModal from "../CreateNewReplyModal/CreateNewReplyModal";
import { useParams } from "react-router-dom";
import ReplyCard from "../ReplyCard/ReplyCard";
import {
  addResponseDislike,
  addResponseLike,
  deleteResponse,
  editSelectedResponse,
  removeResponseDislike,
  removeResponseLike,
  getReplies,
} from "../../utils/api";
import DeleteResponseModal from "../DeleteResponseModal/DeleteResponseModal";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import RenderLogo from "../RenderLogo";

const ResponseCard = ({
  item,
  reload,
  discussionQuestion,
  users,
  responseUser,
  newResponseEmit,
  socket,
  setFetcher,
}) => {
  const params = useParams();
  const { currentLoggedInUser: user } = useContext(CurrentLoggedInUserContext);
  const [editResponseModal, setEditResponseModal] = useState(false);
  const [deleteResponseModal, setDeleteResponseModal] = useState(false);
  const [newReplyModal, setNewReplyModal] = useState(false);
  const [responseId, setResponseId] = useState([]);
  const [replies, setReplies] = useState([]);

  const getUser = (response) => users.find((x) => x._id === response.userid[0]);

  const [show, toggleShow] = useState(true);

  const handleReplies = useCallback(
    (values) => {
      setReplies(values);
      toggleShow(true);
      socket.emit("room", params.id, values);
      reload();
    },
    [params?.id, reload, socket]
  );

  function handleShowReplies(open) {
    toggleShow(open || !show);
    if (open || !show) {
      getReplies(item._id).then((res) => {
        setReplies(res);
        socket.emit("room", params.id, res);
        reload();
      });
    }
  }
  const updatedReplies = item?.replies || replies;

  const adminoruser = getCurrentUserRole();
  const isLiked = item.likes.some((user) => user === getCurrentUser());
  const isDisliked = item.dislikes.some((user) => user === getCurrentUser());
  const showEditIcon = item.userid.includes(getCurrentUser());

  const handleResponseLike = () => {
    isLiked
      ? // if so, send a request to add the user's id to the card's likes array
        removeResponseLike(item._id)
          .then((res) => {
            socket.emit("room", params.id, res, newResponseEmit);
            reload();
          })
          .catch((err) => console.log(err))
      : addResponseLike(item._id)
          .then((res) => {
            socket.emit("room", params.id, res, newResponseEmit);
            reload();
          })
          .catch((err) => console.log(err));
  };
  const handleResponseDislike = () => {
    isDisliked
      ? // if so, send a request to add the user's id to the card's likes array
        removeResponseDislike(item._id)
          .then((res) => {
            socket.emit("room", params.id, res, newResponseEmit);
            reload();
          })
          .catch((err) => console.log(err))
      : addResponseDislike(item._id)
          .then((res) => {
            socket.emit("room", params.id, res, newResponseEmit);
            reload();
          })
          .catch((err) => console.log(err));
  };

  const updateddate = dayjs(item.createdAt).format("MM-DD-YYYY h:mmA");

  const handleEditResponseSubmit = (values) => {
    editSelectedResponse({
      responseid: item._id,
      updatedresponseinfo: values,
    })
      .then((updatedResponse) => {
        setEditResponseModal(false);
        reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleResponseDelete = () => {
    deleteResponse({
      questionid: discussionQuestion._id,
      responseid: item._id,
    })
      .then((res) => {
        console.log(res);
        socket.emit("room", params.id, res, newResponseEmit);
        setDeleteResponseModal(false);
        reload();
        setFetcher(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const userAvatar = user?.contentType?.includes("png");
  let firstName = responseUser?.firstName
    ? responseUser?.firstName[0]?.toLowerCase()
    : "";
  let lastName = responseUser?.lastName
    ? responseUser?.lastName[0]?.toLowerCase()
    : "";
  return (
    <>
      <div className="response__info-container">
        <div className="response__subheader1-container">
          <div className="test_response-stuff">
            {userAvatar ? (
              <RenderLogo
                imageContent={user.imageContent}
                classes="responsecard__responseavatar"
              />
            ) : (
              <div className="responsecard__responseavatar ">
                <div className="responsecard__avatar-letter">
                  {firstName}
                  {lastName}
                </div>
              </div>
            )}
            <div className="response__subheader2-container">
              <p className="card__text-date">
                {responseUser?.firstName} {responseUser?.lastName}
              </p>
              <p className="card__text-date">{responseUser.useraffiliation}</p>
            </div>
          </div>
          {adminoruser === "admin" || showEditIcon ? (
            <div className="response__icon-container">
              <button
                className="button-icon"
                type="button"
                onClick={() => setEditResponseModal(true)}
              >
                <img className="icon-enlarge" src={EditIcon} alt="edit event" />
              </button>
              <button
                className="button-icon"
                type="button"
                onClick={() => setDeleteResponseModal(true)}
              >
                <img
                  className="icon-medium"
                  src={DeleteIcon}
                  alt="edit event"
                />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="response__subheader2-container">
          <p className="card__text-date">
            {item.firstName} {item.lastName}
          </p>
          <p className="card__text-date">{item.useraffiliation}</p>
        </div>

        <div className="responsecard__timedate-response-container">
          <p className="card__text-date">{updateddate}</p>
          <p className="card__text responsetext">
            {item.discussionquestionresponse}
          </p>
        </div>

        <div className="response__icon-container likes">
          <div>
            <button
              className={"button-icon"}
              type="button"
              onClick={() => handleShowReplies()}
              disabled={
                item?.replies?.length === 0 || item?.replies === undefined
              }
            >
              <img
                className="icon-fitlarge"
                src={
                  item?.replies?.length === 0 || item?.replies === undefined
                    ? DisabledExpandIcon
                    : ExpandIcon
                }
                alt="edit event"
              />
              <p className="card__text">{item?.replies?.length}</p>
            </button>
            <button
              className="button-icon"
              type="button"
              onClick={() => {
                setResponseId(item._id);
                setNewReplyModal(true);
              }}
            >
              <img className="icon-fitlarge" src={ReplyIcon} alt="edit event" />
            </button>
          </div>
          <div>
            <button
              className="button-icon"
              type="button"
              onClick={handleResponseLike}
            >
              <img
                className="icon-enlarge"
                src={item.likes.length > 0 ? LikeActiveIcon : LikeInactiveIcon}
                alt="edit event"
              />
              <p className="card__text">{item.likes.length}</p>
            </button>
            <button
              className="button-icon"
              type="button"
              onClick={handleResponseDislike}
            >
              <img
                className="icon-enlarge"
                src={
                  item.dislikes.length > 0
                    ? DislikeActiveIcon
                    : DislikeInactiveIcon
                }
                alt="edit event"
              />
              <p className="card__text">{item.dislikes.length}</p>
            </button>
          </div>
        </div>
        {show &&
          updatedReplies?.map((reply) => {
            return (
              <ReplyCard
                key={reply._id}
                item={reply}
                handleShowReplies={handleShowReplies}
                socket={socket}
                reload={reload}
              />
            );
          })}
      </div>

      {editResponseModal && (
        <EditResponseModal
          onEditResponse={handleEditResponseSubmit}
          currentResponse={item}
          onClose={() => {
            setEditResponseModal(false);
          }}
        />
      )}
      {deleteResponseModal && (
        <DeleteResponseModal
          handleResponseDelete={handleResponseDelete}
          onClose={() => {
            setDeleteResponseModal(false);
          }}
        />
      )}
      {newReplyModal && (
        <CreateNewReplyModal
          responseId={responseId}
          handleReplies={handleReplies}
          onClose={() => {
            setNewReplyModal(false);
          }}
        />
      )}
    </>
  );
};

export default ResponseCard;
