import "./AdminProfileMain.css";

import React from "react";
import EventCard from "../EventCard/EventCard";

function AdminProfileMain({
  handleCreateNewUser,
  handleCreateNewEvent,
  eventinfo,
  onSelectCard,
  handleManageUsers,
  handleDeleteEvent,
}) {
  return (
    <>
      <main className="main">
        <div className="main__admin-forms">
          <button
            type="button"
            className="button button-stretch"
            onClick={handleCreateNewUser}
          >
            ADD NEW USER
          </button>
          <button
            type="button"
            className="button button-stretch"
            onClick={handleManageUsers}
          >
            MANAGE USERS
          </button>
        </div>
        <div className="main__page-section">
          <h2 className="main__headers">Events</h2>
          <button
            type="button"
            className="button button-stretch"
            onClick={handleCreateNewEvent}
          >
            CREATE NEW EVENT
          </button>
          <section className="main__card-section" id="card-section">
            {eventinfo.map((event) => (
              <EventCard
                key={event?._id}
                eventinfo={event}
                onSelectCard={onSelectCard}
                handleDeleteEvent={handleDeleteEvent}
              />
            ))}
          </section>
        </div>
      </main>
    </>
  );
}

export default AdminProfileMain;
