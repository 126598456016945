import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a
          href={process.env.PUBLIC_URL + '/docs/BC_TermsOfService_Nov2023.pdf'}
          target="_blank"
          className="footer-button"
        >
          Terms of Service
        </a>
        <a
          href={process.env.PUBLIC_URL + '/docs/BC_PrivacyPolicy_Nov2023.pdf'}
          target="_blank"
          className="footer-button"
        >
          Privacy Policy
        </a>
      </div>
      <div>Copyright &copy;{(new Date().getFullYear())} blend.works - All rights reserved</div>
    </footer>
  );
};

export default Footer;
