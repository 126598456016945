import React from "react";
import "./LogoutModal.css";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ onClose }) => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
    onClose();
  };
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to log out?
      </p>
      <button className="button button-error" onClick={handleLogOut}>
        CONFIRM LOGOUT
      </button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default LogoutModal;
