import React, { useContext, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import CurrentEventContext from "../../contexts/CurrentEventContext"; // onAddItem refers to handleAddItemSubmit, which is declared in App.js

// onAddItem refers to handleAddItemSubmit, which is declared in App.js
const CreateNewEventResourceModal = ({ isOpen, onAddItem, onClose }) => {
  const currentEvent = useContext(CurrentEventContext);
  // declare state for each input field (name, imageUrl, weather)
  const [eventResourceName, setEventResourceName] = useState("");
  const [eventResourceFile, setEventResourceFile] = useState("");
  const [eventResourceVideo, setEventResourceVideo] = useState("");

  // use a useEffect hook to reset the input field state to empty strings when
  // the modal is opened (isOpen from input)
  useEffect(() => {
    // setUniqueEventID("");
    setEventResourceName("");
    setEventResourceFile("");
    setEventResourceVideo("");
  }, [isOpen]);

  // create onChange handlers corresponding to each state variable (for each setXxx)

  function handleEventResourceName(evt) {
    setEventResourceName(evt.target.value);
  }

  function handleEventResourceFile(evt) {
    const acceptedFile = evt.target.files || [];
    const file = acceptedFile.length === 1 && acceptedFile[0];
    setEventResourceFile(
      file
        ? Object.assign(file, {
            url: URL.createObjectURL(file),
          })
        : ""
    );
  }

  function handleEventResourceVideo(evt) {
    setEventResourceVideo(evt.target.value);
  }

  function handleSubmit(evt) {
    // prevent default behavior
    evt.preventDefault();
    // call onAddItem with appropriate arguments - handleAddItemSubmit
    const eventresource = {};
    eventresource.eventresourcename = eventResourceName;
    eventresource.eventresourcepdf = eventResourceFile;
    eventresource.eventresourcevideo = eventResourceVideo;
    onAddItem(eventresource, currentEvent);
  }

  return (
    <ModalWithForm
      buttonText="Create New Event Resource"
      title="Create New Event Resource"
      onClose={onClose}
      name="new-event-resource"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Event Resource Name*
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Event Resource Name"
          value={eventResourceName}
          onChange={handleEventResourceName}
          required
        />
      </label>
      <p className="modal__subheading">Add a PDF or Video Event Resource</p>
      <div className="modal__resources">
        <label className="modal__label-align modal__label" id="modal-namelabel">
          Event Resource Video
          <input
            className="modal__input"
            id="modal-name"
            type="text"
            name="welcomevideo"
            minLength="1"
            placeholder="Event Resource Video URL"
            value={eventResourceVideo}
            onChange={handleEventResourceVideo}
            disabled={eventResourceFile.length > 0}
          />
        </label>
        <label
          className="modal__label-align modal__label"
          id="modal-imagelabel"
        >
          Event Resource File (.pdf)
          <input
            className="modal__input file-input"
            type="file"
            accept="application/pdf"
            name="event_resourcefile"
            placeholder="File"
            onChange={handleEventResourceFile}
            disabled={eventResourceVideo.length > 0}
          />
        </label>
      </div>

      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default CreateNewEventResourceModal;
