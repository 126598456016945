// import "./UserProfileMain.css";
import React, { useEffect, useState } from "react";
import { getDecodedToken } from "../../utils/auth";
import { ADMIN } from "../../utils/constants";
import { declineEvent, getEventsByUser, rsvpEvent } from "../../utils/api";
import UserCard from "../UserCard/UserCard";
import EventCard from "../EventCard/EventCard";

import { useNavigate } from "react-router-dom";

function AdminProfileInvites({ handleDeleteEvent, onSelectCard }) {
  const [invitedCards, setInvitedCards] = useState([]);
  const [rsvpCards, setRsvpCards] = useState([]);
  const [activeCards, setActiveCardsCards] = useState([]);

  console.log(rsvpCards);

  function getEventsByAdminId() {
    const token = getDecodedToken();
    if (token.role === ADMIN) {
      getEventsByUser(token._id).then((eventitems) => {
        setInvitedCards(eventitems.invited);
        setRsvpCards(eventitems.rsvp);
        setActiveCardsCards(eventitems.active);
      });
    }
  }

  function handleRsvp(id) {
    rsvpEvent(id).then((r) => {
      getEventsByAdminId();
    });
  }

  function handleDecline(id) {
    declineEvent(id).then((r) => {
      getEventsByAdminId();
    });
  }

  const navigate = useNavigate();
  useEffect(() => {
    getEventsByAdminId();
  }, []);
  const handleNavigate = (id) => {
    navigate(`/event/${id}`);
  };
  return (
    <div className="profile">
      <main className="main">
        <div className="main__page-section">
          <h2 className="main__headers">Active Events</h2>
          <section className="main__card-section" id="card-section">
            {Array.isArray(activeCards) &&
              activeCards?.map((item) => (
                <EventCard
                  key={item?._id}
                  eventinfo={item}
                  button_text="Go To Event"
                  onSelectCard={onSelectCard}
                  // onClick={() => handleNavigate(item._id)}
                  handleDeleteEvent={handleDeleteEvent}
                />
              ))}
          </section>
        </div>
        <div className="main__page-section">
          <h2 className="main__headers">Upcoming Accepted Events</h2>
          <section className="main__card-section" id="card-section">
            {rsvpCards.map((item) => (
              <EventCard
                key={item?._id}
                eventinfo={item}
                button_text="Go To Event"
                onSelectCard={onSelectCard}
                // onClick={() => handleNavigate(item._id)}
                handleDeleteEvent={handleDeleteEvent}
              />
            ))}
          </section>
        </div>
        <div className="main__page-section">
          <h2 className="main__headers">Invitations</h2>
          <section className="main__card-section" id="card-section">
            {invitedCards.map((item) => (
              <UserCard
                key={item._id}
                item={item}
                button_text="RSVP"
                onClick={() => handleRsvp(item._id)}
                declineClick={() => handleDecline(item._id)}
                decline_text="Decline"
                decline_button_text="Decline"
              />
            ))}
          </section>
        </div>
      </main>
    </div>
  );
}

export default AdminProfileInvites;
