import React, {memo, useContext, useState} from "react";
import dayjs from "dayjs";

import EditIcon from "../../images/EditIcon.svg";
import DeleteIcon from "../../images/DeleteIcon.svg";
import LikeInactiveIcon from "../../images/like-inactive.svg";
import LikeActiveIcon from "../../images/like-active.svg";
import DislikeInactiveIcon from "../../images/dislike-inactive.svg";
import DislikeActiveIcon from "../../images/dislike-active.svg";
import { getCurrentUser, getCurrentUserRole } from "../../utils/auth";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import RenderLogo from "../../components/RenderLogo";
import EditReplyModal from "../EditReplyModal/EditReplyModal";
import DeleteReplyModal from "../DeleteReplyModal/DeleteReplyModal";
import {
  addReplyLike,
  removeReplyLike,
  addReplyDislike,
  removeReplyDislike,
  deleteReply,
} from "../../utils/api";
import { useParams } from "react-router-dom";

const ReplyCard = ({
  item: replyItem,
  handleShowReplies,
  socket,
  reload,
}) => {
  const { currentLoggedInUser: user } = useContext(CurrentLoggedInUserContext);
  const item = replyItem;
  const adminoruser = getCurrentUserRole();
  const [editResponseModal, setEditResponseModal] = useState(false);
  const [deleteResponseModal, setDeleteResponseModal] = useState(false);
  const params = useParams();


  const showEditIcon = item?.userId?.includes(getCurrentUser());
  const updateddate = dayjs(item.createdAt).format("MM-DD-YYYY h:mmA");
  const userAvatar = user?.contentType?.includes("png");
  const replyUser = replyItem.user
  let firstName = replyUser?.firstName
    ? replyUser?.firstName[0]?.toLowerCase()
    : "";
  let lastName = replyUser?.lastName
    ? replyUser?.lastName[0]?.toLowerCase()
    : "";

  const handleSocketAndReload = () => {
    socket.emit("room", params.id);
    reload();
  };

  const handleLikesDisLikes = (type) => {
    const userId = getCurrentUser();
    console.log(userId);
    if (type === "like") {
      if (!item.replyLikes.includes(userId)) {
        addReplyLike(item._id).then(({ item }) => {
          handleSocketAndReload();
        });
      } else {
        removeReplyLike(item._id).then(({ item }) => {
          handleSocketAndReload();
        });
      }
    }
    if (type === "dislike") {
      if (!item.replyDislikes.includes(userId)) {
        addReplyDislike(item._id).then(({ item }) => {
          handleSocketAndReload();
        });
      } else {
        removeReplyDislike(item._id).then(({ item }) => {
          handleSocketAndReload();
        });
      }
    }
  };

  const handleReplyDelete = () => {
    deleteReply({
      responseId: item.discussionQuestionResponse,
      replyId: item._id,
    }).then(() => {
      handleShowReplies(true);
      setDeleteResponseModal(false);
      handleSocketAndReload();
    });
  };

  function handleOpenReply() {
    setEditResponseModal(true);
  }

  return (
    <>
      <div className="reply">
        <div className="response__info-container">
          <div className="response__subheader1-container">
            <div className="test_response-stuff">
              {userAvatar ? (
                <RenderLogo
                  imageContent={user.imageContent}
                  classes="responsecard__responseavatar"
                />
              ) : (
                <div className="responsecard__responseavatar ">
                  <div className="responsecard__avatar-letter">
                    {firstName}
                    {lastName}
                  </div>
                </div>
              )}
              <div className="response__subheader2-container">
                <p className="card__text-date">
                  {replyUser?.firstName} {replyUser?.lastName}
                </p>
                <p className="card__text-date">{replyUser?.useraffiliation}</p>
              </div>
            </div>
            {adminoruser === "admin" || showEditIcon ? (
              <div className="response__icon-container">
                <button
                  className="button-icon"
                  type="button"
                  onClick={handleOpenReply}
                >
                  <img
                    className="icon-enlarge"
                    src={EditIcon}
                    alt="edit event"
                  />
                </button>
                <button
                  className="button-icon"
                  type="button"
                  onClick={() => setDeleteResponseModal(true)}
                >
                  <img
                    className="icon-medium"
                    src={DeleteIcon}
                    alt="edit event"
                  />
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="response__subheader2-container">
            <p className="card__text-date">
              {item.firstName} {item.lastName}
            </p>
            <p className="card__text-date">{item.useraffiliation}</p>
          </div>
          <div className="responsecard__timedate-response-container">
            <p className="card__text-date">{updateddate}</p>
            <p className="card__text responsetext">{item.replyText}</p>
          </div>
          <div className="response__icon-container likes ">
            <div></div>
            <div>
              <button
                className="button-icon"
                type="button"
                onClick={() => handleLikesDisLikes("like")}
              >
                <img
                  className="icon-enlarge"
                  src={
                    item.replyLikes?.length > 0
                      ? LikeActiveIcon
                      : LikeInactiveIcon
                  }
                  alt="edit event"
                />
                <p className="card__text">{item.replyLikes?.length}</p>
              </button>
              <button
                className="button-icon"
                type="button"
                onClick={() => handleLikesDisLikes("dislike")}
              >
                <img
                  className="icon-enlarge"
                  src={
                    item.replyDislikes?.length > 0
                      ? DislikeActiveIcon
                      : DislikeInactiveIcon
                  }
                  alt="edit event"
                />
                <p className="card__text">{item.replyDislikes?.length}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      {editResponseModal && (
        <EditReplyModal
          item={item}
          onClose={() => {
            setEditResponseModal(false);
          }}
          handleSocketAndReload={handleSocketAndReload}
        />
      )}
      {deleteResponseModal && (
        <DeleteReplyModal
          handleResponseDelete={handleReplyDelete}
          onClose={() => {
            setDeleteResponseModal(false);
          }}
        />
      )}
    </>
  );
};

export default memo(ReplyCard);
