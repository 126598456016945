import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

const CreateNewUserModal = ({ isOpen, onAddItem, onClose }) => {
  const [userEmail, setUserEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userAffiliation, setUserAffiliation] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [userEvents, setUserEvents] = useState([]);
  const [userStatus, setUserStatus] = useState("active");

  useEffect(() => {
    setUserEmail("");
    setFirstName("");
    setLastName("");
    setUserAffiliation("");
    setUserRole("");
    setUserAvatar("");
    setUserEvents([]);
    setUserStatus("active");
  }, [isOpen]);

  function handleUserEmailChange(evt) {
    setUserEmail(evt.target.value);
  }

  function handleFirstName(evt) {
    setFirstName(evt.target.value);
  }

  function handleLastName(evt) {
    setLastName(evt.target.value);
  }

  function handleUserAffiliationChange(evt) {
    setUserAffiliation(evt.target.value);
  }

  function handleuserRoleChange(evt) {
    setUserRole(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const userinfo = {};
    userinfo.useremail = userEmail;
    userinfo.firstName = firstName;
    userinfo.lastName = lastName;
    userinfo.useraffiliation = userAffiliation;
    userinfo.userrole = userRole;
    userinfo.useravatar = userAvatar;
    userinfo.userinvitedevents = userEvents;
    userinfo.userstatus = userStatus;
    onAddItem(userinfo);
  }

  return (
    <ModalWithForm
      buttonText="Add New User"
      title="Add New User"
      onClose={onClose}
      name="new-user-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Email*
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="email"
          name="name"
          minLength="1"
          placeholder="Email"
          value={userEmail}
          onChange={handleUserEmailChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        First Name*
        <input
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="2"
          maxLength="32"
          placeholder="First Name"
          value={firstName}
          onChange={handleFirstName}
          required
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        Last Name*
        <input
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="2"
          maxLength="32"
          placeholder="Last Name"
          value={lastName}
          onChange={handleLastName}
          required
        />
      </label>
      <label className="modal__label" id="modal-modal-namelabel">
        Affiliation*
        <input
          className="modal__input"
          id="modal-name"
          type="text"
          name="affiliation"
          minlength="1"
          maxlength="64"
          placeholder="Affiliation"
          value={userAffiliation}
          onChange={handleUserAffiliationChange}
          required
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
      <p className="modal__subheading">Select New User Type</p>
      <div className="radio-buttons">
        <label className="radio-description">
          <input
            className="button"
            type="radio"
            id="user"
            value="user"
            name="userRole"
            checked={userRole === "user"}
            onChange={handleuserRoleChange}
          />
          User
        </label>

        <label className="radio-description">
          <input
            className="button"
            type="radio"
            id="admin"
            value="admin"
            name="userRole"
            checked={userRole === "admin"}
            onChange={handleuserRoleChange}
          />
          Administrator
        </label>
      </div>
    </ModalWithForm>
  );
};

export default CreateNewUserModal;
