import { io } from "socket.io-client";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

function useSocket(setLoadAttempt) {
  const [socket, setSocket] = useState(null);
  const [fetcher, setFetcher] = useState(false);
  const params = useParams();

  useEffect(() => {
    const socketSetup = io(process.env.REACT_APP_ENV, {
      transports: ["websocket"],
    });
    setSocket(socketSetup);
    if (!socketSetup) return;
    return () => socketSetup.disconnect();
  }, []);

  const handleReconnect = useCallback(() => {
    if (socket && !socket.connected) {
      console.log("Attempting to reconnect...");
      socket.connect();
      if (!fetcher) setLoadAttempt(false);
      setFetcher(false);
    }
  }, [socket, fetcher, params]);

  useEffect(() => {
    if (!socket) return;
    socket.emit("join", params.id);
    socket.on("postresponse-from-server", (data) => {
      console.log("post received", data);
      if (!fetcher) setLoadAttempt(false);
      setFetcher(false);
    });
    handleReconnect();
  }, [socket, fetcher, params]);

  return { socket, setFetcher };
}

export default useSocket;
