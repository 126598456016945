import "./AdminProfile.css";

import React, { useEffect, useState } from "react";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import AdminProfileMain from "../AdminProfileMain/AdminProfileMain";
import { getDecodedToken } from "../../utils/auth";
import { ADMIN } from "../../utils/constants";
import { addEventItem, deleteEvent, getEventsByAdmin } from "../../utils/api";
import ManageUsersModal from "../ManageUsersModal/ManageUsersModal";
import EditUserInfoModal from "../EditUserInfoModal/EditUserInfoModal";
import DeleteEventModal from "../DeleteEventModal/DeleteEventModal";
import CreateNewEventModal from "../CreateNewEventModal/CreateNewEventModal";

import AdminProfileInvites from "../AdminProfileInvites/AdminProfileInvites";

function AdminProfile({
  handleProfileEdit,
  onLogout,
  handleCreateNewUser,
  downloadeventinfo,
  onSelectCard,
  handleCardClick,
  handleEditEvent,
  handleInviteUsers,
  handleEventManagmentModal,
}) {
  const [eventCards, setEventCards] = useState([]);
  const [manageUsersModal, setManageUsersModal] = useState(false);
  const [editUserInfoModal, setEditUserInfoModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [deleteEventId, setDeleteEvent] = useState(null);
  const [newEventModal, setNewEventModal] = useState(false);
  const [loadAttempt, setLoadAttempt] = useState(false);

  const openConfirmDeleteEventModal = (id) => {
    setDeleteEventModal(true);
    setDeleteEvent(id);
  };

  const openCreateNewEventModal = () => {
    setNewEventModal(true);
  };

  const openEditUserInfoModal = () => {
    setEditUserInfoModal(true);
  };

  const handleEventDelete = () => {
    deleteEvent(deleteEventId)
      .then(() => {
        setDeleteEventModal(false);
        setLoadAttempt(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddEventSubmit = (values) => {
    addEventItem(values)
      .then((newEventCard) => {
        setNewEventModal(false);
        setLoadAttempt(false);
      })
      .catch((err) => {
        console.log(err.err);
      });
  };

  useEffect(() => {
    if (!loadAttempt) {
      const token = getDecodedToken();
      if (token.role === ADMIN) {
        getEventsByAdmin(token._id).then((eventitems) => {
          setEventCards(eventitems);
          setLoadAttempt(true);
        });
      }
    }
  }, [loadAttempt]);

  return (
    <>
      <div className="profile">
        <ProfileHeader
          handleProfileEdit={handleProfileEdit}
          onLogout={onLogout}
        />
        <AdminProfileInvites
          handleDeleteEvent={openConfirmDeleteEventModal}
          onSelectCard={onSelectCard}
        />
        <AdminProfileMain
          handleCreateNewUser={handleCreateNewUser}
          handleCreateNewEvent={openCreateNewEventModal}
          eventinfo={eventCards}
          downloadeventinfo={downloadeventinfo}
          onSelectCard={onSelectCard}
          handleCardClick={handleCardClick}
          handleEditEvent={handleEditEvent}
          handleInviteUsers={handleInviteUsers}
          handleEventManagmentModal={handleEventManagmentModal}
          handleManageUsers={() => setManageUsersModal(true)}
          handleDeleteEvent={openConfirmDeleteEventModal}
        />
      </div>
      {manageUsersModal && (
        <ManageUsersModal
          handleEditUserInfo={() => {
            setManageUsersModal(false);
            openEditUserInfoModal();
          }}
          onClose={() => {
            setManageUsersModal(false);
          }}
          setSelectedUser={setSelectedUser}
        />
      )}
      {editUserInfoModal && (
        <EditUserInfoModal
          handleAdminEditUserProfile={() => {
            setEditUserInfoModal(false);
            setManageUsersModal(false);
          }}
          selectedUser={selectedUser}
          onClose={() => {
            setEditUserInfoModal(false);
            setManageUsersModal(false);
          }}
        />
      )}

      {deleteEventModal && (
        <DeleteEventModal
          handleEventDelete={handleEventDelete}
          onClose={() => {
            setDeleteEventModal(false);
          }}
        />
      )}
      {newEventModal && (
        <CreateNewEventModal
          onAddItem={handleAddEventSubmit}
          onClose={() => {
            setNewEventModal(false);
          }}
        />
      )}
    </>
  );
}

export default AdminProfile;
