import React, { useContext, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

import CurrentEventContext from "../../contexts/CurrentEventContext";

const RemoveUserPrelaunchModal = ({
  isOpen,
  onClose,
  selectedCardInfo,
  onRemoveUser,
  invitedUsers,
  setInvitedUsers,
}) => {
  const currentEvent = useContext(CurrentEventContext);
  const currentEventinvitees = currentEvent.eventinvitedusers;
  const invitedafter = selectedCardInfo.eventinvitedusers.filter((invitee) =>
    currentEventinvitees.includes(invitee._id),
  );
  const [filteredUserData, setFilteredUserData] = useState(invitedafter);
  const search = (e) =>
    setFilteredUserData(
      selectedCardInfo.filter((item) =>
        item.useremail.includes(e.target.value),
      ),
    );

  function handleSubmit(evt) {
    evt.preventDefault();
    onRemoveUser(invitedUsers);
  }

  let handleInputChange = (e) => {
    const { checked } = e.target;
    const userid = e.target.value;
    const existingUser = invitedUsers.includes(userid);
    if (!checked && existingUser) {
      setInvitedUsers([...invitedUsers, userid]);
    } else if (checked && existingUser) {
      setInvitedUsers(
        invitedUsers.filter((removeduser) => removeduser !== userid),
      );
    }
  };

  return (
    <ModalWithForm
      buttonText="Remove Invited Users"
      title="Remove Users"
      onClose={onClose}
      name="new-user-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Search Users
        <input
          className="input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Search"
          onKeyUp={search}
        />
        <ul className="modal__list">
          {filteredUserData.map((user) => (
            <li key={user._id}>
              <input
                type="checkbox"
                name="invitedusers"
                id="check-users"
                value={user._id}
                onClick={handleInputChange}
              />
              <label htmlFor="check-users">
                {user.username},{user.useraffiliation},{user.useremail}
              </label>
            </li>
          ))}
        </ul>
      </label>
    </ModalWithForm>
  );
};

export default RemoveUserPrelaunchModal;
