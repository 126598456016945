//Item Card Component
import React from "react";
import "./UserCard.css";
import GoToIcon from "../../images/GoToIcon.svg";

const UserCard = ({
  item,
  button_text,
  onClick,
  declineClick,
  decline_text,
  decline_button_text,
}) => {
  return (
    <div className="card">
      <div className="card__text-container">
        <h3 className="card__text-title">{item.eventname}</h3>
        <p className="card__text-date">
          {item.eventstartdate.slice(0, 10)} TO {item.eventenddate.slice(0, 10)}
        </p>
        {button_text !== "Starting Soon" ? (
          <>
            {button_text === "RSVP" ? (
              <div className="flex_buttons">
                <button className="button" type="button" onClick={onClick}>
                  {button_text}
                </button>
                {decline_text === "Decline" && (
                  <button
                    className="button"
                    type="button"
                    onClick={declineClick}
                  >
                    {decline_button_text}
                  </button>
                )}
              </div>
            ) : (
              <button className="button-icon" type="button" onClick={onClick}>
                <img
                  className="icon-enlarge"
                  src={GoToIcon}
                  alt="Go to event"
                />
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UserCard;
