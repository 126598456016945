import "./App.css";

import React, { useCallback, useEffect, useState } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import ReactGA from "react-ga4";

import HomePage from "../HomePage/HomePage";
import AdminProfile from "../AdminProfile/AdminProfile";
import LogoutModal from "../LogoutModal/LogoutModal";

import CreateNewUserModal from "../CreateNewUserModal/CreateNewUserModal";

import AdminEventPage from "../AdminEventPage/AdminEventPage";
import WelcomeVideoModal from "../WelcomeVideoModal/WelcomeVideoModal";
import ManageInviteUserModal from "../ManageInviteUserModal/ManageInviteUserModal";

import AdminTopicPage from "../AdminTopicPage/AdminTopicPage";

import AdminDiscussionBoardPage from "../AdminDiscussionBoardPage/AdminDiscussionBoardPage";

import {
  getEvent,
  getEventResources,
  getEventTopics,
  getUser,
  updateUserInviteStatus,
} from "../../utils/api";

import { addNewUser, checkToken, signIn } from "../../utils/auth";

import CurrentEventContext from "../../contexts/CurrentEventContext";
import CurrentTopicContext from "../../contexts/CurrentTopicContext";
import CurrentDiscussionQuestionContext from "../../contexts/CurrentDiscussionQuestionContext";
import CurrentResponseContext from "../../contexts/CurrentResponseContext";
import CurrentReplyContext from "../../contexts/CurrentReplyContext";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import CurrentEventTopicListContext from "../../contexts/CurrentEventTopicsList";
import CurrentEventResourceListContext from "../../contexts/CurrentEventResourcesList";
import CurrentTopicDiscussionQuestionListContext from "../../contexts/CurrentTopicDiscussionQuestionsList";
import CurrentTopicResourcesListContext from "../../contexts/CurrentTopicResourcesList";
import CurrentDiscussionQuestionResponseListContext from "../../contexts/CurrentDiscussionQuestionResponsesList";
import CurrentResponseRepliesListContext from "../../contexts/CurrentResponseRepliesList";
import Footer from "../Footer/Footer";
import UserProfileMain from "../UserProfileMain/UserProfileMain";
import { ADMIN, INVALID_LOGIN, USER } from "../../utils/constants";
import EventPage from "../EventPage/EventPage";
import TopicPage from "../TopicPage/TopicPage";
import DiscussionBoardPage from "../DiscussionBoardPage/DiscussionBoardPage";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorBoundary/ErrorFallback";

const TRACKING_ID = "G-QW6B9WKY66";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [activeModal, setActiveModal] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState();
  const [currentLoggedInUser, setCurrentLoggedInUser] = useState({});
  const [logoutModal, setLogoutModal] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const [currentUser, setCurrentUser] = useState({});

  const [userLoggedInEventCards, setUserLoggedInEventCards] = useState([]);
  const [selectedEventCard, setSelectedEventCard] = useState([]);
  const [eventManagmentModal, setEventManagementModal] = useState(false);

  const [userEventInvite, setUserEventInvite] = useState({});
  const [currentUserEventsList, setCurrentUserEventsList] = useState([]);
  const [manageInviteUserModal, setManageInviteUserModal] = useState(false);
  const [
    confirmAddEventToUserProfileModal,
    setConfirmAddEventToUserProfileModal,
  ] = useState(false);
  const [manageEventLaunchModal, setManageEventLaunchModal] = useState(false);
  const [
    removeActiveEventfromUserProfileModal,
    setRemoveActiveEventfromUserProfileModal,
  ] = useState(false);

  const [selectedTopicCard, setSelectedTopicCard] = useState([]);

  const [selectedEventResourceCard, setSelectedEventResourceCard] = useState(
    [],
  );

  //TopicPage-DiscussionQuestionCard
  const [
    selectedTopicDiscussionQuestions,
    setSelectedTopicDiscussionQuestions,
  ] = useState([]);
  const [selectedDiscussionQuestionCard, setSelectedDiscussionQuestionCard] =
    useState([]);
  //TopicPage-DiscussionQuestionCard-DiscussionQuestionManagement
  const [selectedTopicResources, setSelectedTopicResources] = useState([]);

  const [selectedEventResources, setSelectedEventResources] = useState([]);
  const [selectedResponseCard, setSelectedResponseCard] = useState([]);
  const [selectedResponseReplies, setSelectedResponseReplies] = useState([]);
  const [selectedReply, setSelectedReply] = useState([]);

  const handleCloseModal = () => {
    setActiveModal("");
  };

  const openLogoutModal = () => {
    setLogoutModal(true);
    handleCloseModal();
  };
  const openCreateNewUserModal = () => {
    setNewUserModal(true);
  };
  //CreateNewEvent Form
  const openEventManagementModal = () => {
    setEventManagementModal(true);
    handleCloseModal();
  };
  //LaunchEvent From

  //DeleteEvent Form

  const openAddEventConfirmationModal = () => {
    setConfirmAddEventToUserProfileModal(true);
    handleCloseModal();
  };
  // Manage Event Launch Modal
  const openManageEventLaunchModal = () => {
    setManageEventLaunchModal(true);
    handleCloseModal();
  };

  //EventResourceModal Popup
  const handleEventWelcomeVideo = () => {
    setActiveModal("previeweventwelcome");
    // setSelectedEventResourceCard(card);
  };

  // RemoveActiveEvent from User DB Modal
  const openRemoveActiveEventFromUserProfileModal = () => {
    setRemoveActiveEventfromUserProfileModal(true);
    handleCloseModal();
  };

  const handleSelectedEventResourceCard = (card) => {
    setActiveModal("previeweventresource");
    setSelectedEventResourceCard(card);
  };

  //Edit
  //ReplyinThread

  //CreateNewResponse Form

  const [selectedEventTopics, setSelectedEventTopics] = useState([]);
  const handleSelectedCard = (eventcard) => {
    setSelectedEventCard(eventcard);
    const eventtopiclist = eventcard.eventtopics;
    const eventresourcelist = eventcard.eventresources;
    getEventTopics({
      eventidarray: eventtopiclist,
    })
      // setSelectedEventTopics with object data included
      .then((selectedEventTopicsData) => {
        // setSelectedEventTopics(selectedEventTopicsData);
      })
      .then(() =>
        getEventResources({
          eventidarray: eventresourcelist,
        }).then((selectedEventResourcesData) =>
          setSelectedEventResources(selectedEventResourcesData),
        ),
      );
  };

  const navigate = useNavigate();

  const navigateAfterLogin = useCallback(
    (token) => {
      const decodedToken = jwt_decode(token);
      if (decodedToken.role === ADMIN) {
        navigate("/adminprofile");
      }
      if (decodedToken.role === USER) {
        navigate("/profile");
      }
    },
    [navigate],
  );

  const handleLogin = ({ useremail, userpassword }) => {
    return signIn(useremail, userpassword)
      .then((res) => {
        if (res && res.token) {
          setToken(res.token);
          localStorage.setItem("jwt", res.token);
          checkToken(res.token).then((userinfo) => {
            setCurrentLoggedInUser(userinfo);
            setIsLoggedIn(true);
            ReactGA.set({ userId: userinfo._id });
            navigateAfterLogin(res.token);
          });
        } else {
          return Promise.resolve(INVALID_LOGIN);
        }
      })
      .catch((err) => {
        console.log(err);
        return Promise.resolve(INVALID_LOGIN);
      });
  };

  const handleNewUserSubmit = (values) => {
    addNewUser(values)
      .then((newUserInfo) => {
        setUserInfo({ ...userInfo, newUserInfo });
        setNewUserModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //CreateNewEvent

  const handleAddSelectedEventToUserProfile = () => {
    const checker = currentUserEventsList.some(
      (listedevent) => listedevent.eventid === userEventInvite.eventid,
    );
    if (!checker) {
      setCurrentUserEventsList([...currentUserEventsList, userEventInvite]);
    } else {
      setCurrentUserEventsList(currentUserEventsList);
    }
  };
  const handleAcceptInvite = (item) => {
    getUser({ id: currentLoggedInUser._id, token }).then((res) => {
      const userInformation = res.userinvitedevents;
      userInformation.map((events) => {
        if (events.eventid === item.eventid._id) {
          events["userinvitestatus"] = "accepted";
        }
      });
      updateUserInviteStatus({
        id: currentLoggedInUser._id,
        userinvitedevents: userInformation,
      }).then((userdata) => {
        setCurrentLoggedInUser(userdata);
        const userEventsList = userdata?.userinvitedevents;
        userEventsList.map((userevent) => {
          getEvent({ id: userevent.eventid }).then((eventdata) => {
            userevent["eventid"] = eventdata;
          });
        });
        setUserLoggedInEventCards(userEventsList);
      });
    });
  };

  const handleAddEventToContext = (eventId) => {
    setSelectedEventCard(eventId);
  };

  //get Token from local storage
  useEffect(() => {
    const storedToken = localStorage.getItem("jwt");
    if (storedToken === null) {
      navigate("/");
    }
    if (storedToken) {
      setToken(storedToken);
      checkToken(storedToken)
        .then((res) => {
          if (res) {
            setUserInfo(res);
            setCurrentLoggedInUser(res);
            setIsLoggedIn(true);
            ReactGA.set({ userId: res._id });
            // navigateAfterLogin(storedToken);
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }
  }, [isLoggedIn, navigate, navigateAfterLogin]);
  let handleCreateNewTopic = () => {
    setSelectedEventTopics();
  };

  return (
    <div className="App">
      <CurrentLoggedInUserContext.Provider
        value={{ currentLoggedInUser, setCurrentLoggedInUser }}
      >
        <CurrentEventContext.Provider
          value={{ selectedEventCard, handleAddEventToContext }}
        >
          <CurrentEventTopicListContext.Provider value={selectedEventTopics}>
            <CurrentEventResourceListContext.Provider
              value={selectedEventResources}
            >
              <CurrentTopicContext.Provider value={selectedTopicCard}>
                <CurrentTopicDiscussionQuestionListContext.Provider
                  value={selectedTopicDiscussionQuestions}
                >
                  <CurrentTopicResourcesListContext.Provider
                    value={selectedTopicResources}
                  >
                    <CurrentDiscussionQuestionContext.Provider
                      value={selectedDiscussionQuestionCard}
                    >
                      <CurrentDiscussionQuestionResponseListContext.Provider
                        value={{}}
                      >
                        <CurrentResponseContext.Provider
                          value={selectedResponseCard}
                        >
                          <CurrentResponseRepliesListContext.Provider
                            value={selectedResponseReplies}
                          >
                            <CurrentReplyContext.Provider value={selectedReply}>
                              <CurrentUserContext.Provider value={currentUser}>
                                <ErrorBoundary fallback={ErrorFallback}>
                                  <Routes>
                                    <Route
                                      exact
                                      path="/"
                                      element={
                                        <HomePage onUserLogin={handleLogin} />
                                      }
                                    />
                                    <Route
                                      path="/adminprofile"
                                      exact
                                      userLoggedIn={isLoggedIn}
                                      element={
                                        <AdminProfile
                                          userLoggedIn={isLoggedIn}
                                          loggedInUserEvents={
                                            userLoggedInEventCards
                                          }
                                          onLogout={openLogoutModal}
                                          handleCreateNewUser={
                                            openCreateNewUserModal
                                          }
                                          onSelectCard={handleSelectedCard}
                                          handleEventManagmentModal={
                                            openEventManagementModal
                                          }
                                          handleAcceptInvite={
                                            handleAcceptInvite
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      path="/profile"
                                      element={<UserProfileMain />}
                                    />
                                    <Route
                                      path="/event/:eventId"
                                      element={
                                        <EventPage
                                          userLoggedIn={isLoggedIn}
                                          onSelectCard={handleSelectedCard}
                                          handleEventWelcomeVideo={
                                            handleEventWelcomeVideo
                                          }
                                          handleCreateNewTopic={
                                            handleCreateNewTopic
                                          }
                                          onSelectTopicCard={{}}
                                          onSelectEventResourceCard={
                                            handleSelectedEventResourceCard
                                          }
                                          handleManageEventLaunchModal={
                                            openManageEventLaunchModal
                                          }
                                          handleOpenRemoveEventFromUserProfileModal={
                                            openRemoveActiveEventFromUserProfileModal
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      path="/eventtopic/:id"
                                      userLoggedIn={isLoggedIn}
                                      element={<TopicPage />}
                                    />
                                    <Route
                                      path="/adminevent/:eventId"
                                      exact
                                      userLoggedIn={isLoggedIn}
                                      element={<AdminEventPage />}
                                    />

                                    <Route
                                      path="/admineventtopic/:id"
                                      userLoggedIn={isLoggedIn}
                                      element={<AdminTopicPage />}
                                    />
                                    <Route
                                      path="/admineventtopicdiscussionboard/:id"
                                      userLoggedIn={isLoggedIn}
                                      element={<AdminDiscussionBoardPage />}
                                    />
                                    <Route
                                      path="/eventtopicdiscussionboard/:id"
                                      userLoggedIn={isLoggedIn}
                                      element={<DiscussionBoardPage />}
                                    />
                                  </Routes>
                                </ErrorBoundary>
                                {activeModal === "previeweventwelcome" && (
                                  <WelcomeVideoModal
                                    onClose={handleCloseModal}
                                  />
                                )}
                                {logoutModal && (
                                  <LogoutModal
                                    onClose={() => {
                                      setLogoutModal(false);
                                    }}
                                  />
                                )}
                                {newUserModal && (
                                  <CreateNewUserModal
                                    onAddItem={handleNewUserSubmit}
                                    onClose={() => {
                                      setNewUserModal(false);
                                    }}
                                  />
                                )}
                                {manageInviteUserModal && (
                                  <ManageInviteUserModal
                                    selectedUser={currentUser}
                                    handleAddSelectedEventToUserProfile={
                                      handleAddSelectedEventToUserProfile
                                    }
                                    handleOpenAddEventConfirmationModal={() => {
                                      setManageInviteUserModal(false);
                                      openAddEventConfirmationModal();
                                    }}
                                    onClose={() => {
                                      setManageInviteUserModal(false);
                                    }}
                                  />
                                )}
                              </CurrentUserContext.Provider>
                            </CurrentReplyContext.Provider>
                          </CurrentResponseRepliesListContext.Provider>
                        </CurrentResponseContext.Provider>
                      </CurrentDiscussionQuestionResponseListContext.Provider>
                    </CurrentDiscussionQuestionContext.Provider>
                  </CurrentTopicResourcesListContext.Provider>
                </CurrentTopicDiscussionQuestionListContext.Provider>
              </CurrentTopicContext.Provider>
            </CurrentEventResourceListContext.Provider>
          </CurrentEventTopicListContext.Provider>
        </CurrentEventContext.Provider>
      </CurrentLoggedInUserContext.Provider>
      <Footer />
    </div>
  );
}

export default App;
