import React from "react";

const DeleteDiscussionQuestionModal = ({
  handleDiscussionQuestionDelete,
  onClose,
}) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to delete this discussion question?
      </p>
      <button
        className="button button-error"
        onClick={handleDiscussionQuestionDelete}
      >
        Yes, delete discussion question
      </button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default DeleteDiscussionQuestionModal;
