import React, { useCallback, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import {
  addUserToEvent,
  getInvitedUsers,
  inviteUsers,
  removeUserFromEvent,
  searchByUser,
} from "../../utils/api";

import "./ManageEventAttendees.css";
import { useParams } from "react-router-dom";

const InviteUserModal = ({ onClose, event, handleGetEvent }) => {
  const params = useParams();
  const [users, setUsers] = useState([]);
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");

  const [checkedAll, setCheckedAll] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleSelectAll = useCallback(() => {
    if (checkedAll.length !== users.length) {
      setCheckedAll(users.map((user) => user._id));
      setSelected(users.map((user) => user._id));
    }
    if (checkedAll.length === users.length) {
      setCheckedAll([]);
      setSelected([]);
    }
  }, [checkedAll, users, setCheckedAll]);

  const handleSelect = useCallback(
    (e) => {
      const value = e.target.value;
      if (selected.includes(value)) {
        setSelected(selected.filter((x) => x !== value));
      } else {
        setSelected([...selected, value]);
      }
    },
    [setSelected, selected],
  );

  function handleSearch(e) {
    e.preventDefault();
    console.log(e.target.value);
    const searchWord = e.target.value;
    setSearch(searchWord);
    if (searchWord.length > 3) {
      searchByUser(searchWord).then((res) => {
        setSearchResults(res);
      });
    }
  }

  function handleAddUser(id) {
    if (params.eventId) {
      addUserToEvent(params.eventId, id).then((res) => {
        setSearchResults([]);
        setSearch("");
        handleGetEvent().then((event) =>
          getInvitedUsersByEvent(event.eventinvitedusers),
        );
      });
    }
  }

  function handleInviteUsers(e) {
    e.preventDefault();
    inviteUsers(selected, event._id).then(() => getInvitedUsersByEvent());
  }

  function handleRemoveUsers(e) {
    e.preventDefault();
    removeUserFromEvent(params.eventId, selected).then(() =>
      handleGetEvent().then((event) =>
        getInvitedUsersByEvent(event.eventinvitedusers),
      ),
    );
  }

  function getInvitedUsersByEvent(values) {
    getInvitedUsers(
      values || event.eventinvitedusers.concat(event.rsvped),
    ).then((res) => {
      setUsers(res);
      setSelected([]);
    });
    setLoadAttempt(true);
  }

  useEffect(() => {
    if (!loadAttempt) {
      getInvitedUsersByEvent();
    }
    if (selected.length > 0 && selected.length === users.length) {
      setCheckedAll(users.map((i) => i._id));
    } else if (selected.length !== users.length) {
      setCheckedAll([]);
    }
  }, [
    users,
    selected,
    setCheckedAll,
    loadAttempt,
    setLoadAttempt,
    event.eventinvitedusers,
  ]);
  return (
    <ModalWithForm
      buttonText=""
      title="Manage Event Attendees"
      onClose={onClose}
      name="new-user-card"
      onSubmit={() => {}}
    >
      <div>
        <div className="search-anchor">
          <div className="button-group">
            <button className="button" onClick={handleRemoveUsers}>
              Remove from Event
            </button>
            <button className="button" onClick={handleInviteUsers}>
              Send Invite Email
            </button>
          </div>
          <input
            className="search__input"
            type="text"
            placeholder="Search by user name or email"
            value={search}
            onChange={handleSearch}
          />
          {searchResults.length > 0 && (
            <div className="search-results">
              {searchResults.map((result) => (
                <div
                  className="search-result"
                  onClick={() => handleAddUser(result._id)}
                >
                  <div>{result.username}</div>
                  <div>{result.useremail}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        {users.length !== 0 ? (
          <table className="table">
            <tr>
              <td>
                <input
                  type="checkbox"
                  checked={checkedAll.length === users.length}
                  onClick={handleSelectAll}
                />
              </td>
              <th className="table__headers">Email</th>
              <th className="table__headers">User Name</th>
              <th className="table__headers">User Affiliation</th>
              <th className="table__headers">Role</th>
            </tr>

            {users &&
              users.map((user) => (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      value={user._id}
                      checked={selected.includes(user._id)}
                      onClick={handleSelect}
                    />
                  </td>
                  <td className="table__values"> {user.useremail}</td>
                  <td className="table__values">
                    {user.firstName} {user.lastName}
                  </td>
                  <td className="table__values">
                    <div className="truncation">{user.useraffiliation}</div>
                  </td>
                  <td className="table__values"> {user.userrole}</td>
                </tr>
              ))}
          </table>
        ) : (
          <div className="box">Please add users to this event</div>
        )}
      </div>
    </ModalWithForm>
  );
};

export default InviteUserModal;
