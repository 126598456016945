import React, { useContext, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

import CurrentEventContext from "../../contexts/CurrentEventContext";

const AddEventToUserProfileModal = ({
  isOpen,
  onClose,
  userinfodata,
  setUserEventInvite,
  setCurrentUser,
  setCurrentUserEventsList,
  handleUserInviteManagement,
}) => {
  const currentEvent = useContext(CurrentEventContext);
  const inviteduserslist = currentEvent.eventinvitedusers;
  const invitedafter = userinfodata.eventinvitedusers?.filter(
    (invitee) => inviteduserslist.includes(invitee._id) || [],
  );

  const [filteredUserData, setFilteredUserData] = useState(invitedafter); //this is fine and works

  function handleSubmit(evt) {
    evt.preventDefault();
    onClose();
  }

  let handleAddEventToUser = (e, user) => {
    setCurrentUser(user);
    setUserEventInvite({
      eventid: currentEvent._id,
      userinvitestatus: "pendingrsvp",
    });
    setCurrentUserEventsList(user.userinvitedevents);
    handleUserInviteManagement();
  };

  return (
    <ModalWithForm
      buttonText="Close Invite User"
      title="Add Event to User Profile "
      onClose={onClose}
      name="new-user-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <ul className="modal__list">
        {filteredUserData?.map((user) => {
          return (
            <li key={user._id}>
              {user.username}, {user.useraffiliation}, {user.useremail},
              <button
                className="button"
                type="button"
                onClick={(e) => handleAddEventToUser(e, user)}
              >
                Add Event to User Profile
              </button>
            </li>
          );
        })}
      </ul>
    </ModalWithForm>
  );
};

export default AddEventToUserProfileModal;
