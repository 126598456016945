import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TopicCard.css";
import DeleteIcon from "../../images/DeleteIcon.svg";
import GoToIcon from "../../images/GoToIcon.svg";
import { deleteTopic } from "../../utils/api";
import DeleteTopicModal from "../DeleteTopicModal/DeleteTopicModal";
import { isUserAdmin } from "../../utils/auth";

const TopicCard = ({ topic, event, reload }) => {
  const [topicManagmentModal, setTopicManagmentModal] = useState(false);
  const navigate = useNavigate();
  const isAdmin = isUserAdmin();
  const eventTopicPage = () => {
    if (isAdmin) {
      navigate(`/admineventtopic/${topic._id}`);
    } else {
      navigate(`/eventtopic/${topic._id}`);
    }
  };
  const openTopicManagmentModal = () => {
    setTopicManagmentModal(true);
  };
  const handleTopicDelete = () => {
    deleteTopic({
      eventid: event._id,
      topicid: topic._id,
    })
      .then(() => {
        setTopicManagmentModal(false);
        reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card__text-container">
          <h3 className="card__text-title"> {topic.topicname}</h3>
          <button
            className="button-icon"
            type="button"
            onClick={eventTopicPage}
          >
            <img className=" icon-enlarge" src={GoToIcon} alt="Go to event" />
          </button>
        </div>
        <div className="card__statusicons-container">
          {isAdmin && <p className="card__text-status"> {topic.topicstatus}</p>}
          {isAdmin && (
            <div className="icon-container">
              <button
                type="button"
                className="button-icon"
                onClick={openTopicManagmentModal}
              >
                <img className="icon" src={DeleteIcon} alt="delete event" />
              </button>
            </div>
          )}
        </div>
      </div>
      {topicManagmentModal && (
        <DeleteTopicModal handleTopicDelete={handleTopicDelete} />
      )}
    </>
  );
};

export default TopicCard;
