import React, { useCallback, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import DeleteIcon from "../../images/DeleteIcon.svg";
import { addPolling } from "../../utils/api";

const CreatePollModal = ({ onClose, questionId, reload }) => {
  const [pollOptions, setPollOptions] = useState(["", ""]);

  const [optionNum, setOptionNum] = useState("");
  const [max, setMax] = useState(null);

  const onOptionChange = (e) => {
    setOptionNum(e.target.value);
  };

  const addOption = () => {
    setPollOptions([...pollOptions, ""]);
  };

  const handleChange = (index, value) => {
    const updatedOptions = pollOptions.map((text, i) =>
      i === index ? value : text
    );
    setPollOptions(updatedOptions);
  };

  const deleteOption = (index) => {
    const updatedOptions = pollOptions.filter((_, i) => i !== index);
    setPollOptions(updatedOptions);
  };  
  
  const onMax = (e) => {
    setMax(e.target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    console.log(pollOptions);
    const mapOrder = pollOptions.map((x, index) => ({ order: index, option: x}))
    console.log({
      polls : mapOrder,
      type: optionNum,
      max,
      questionId
    })
    addPolling({
      polls : mapOrder,
      type: optionNum,
      max,
      questionId
    }).then(() => {
      reload();
      onClose();
    })


  }

  return (
    <ModalWithForm
      buttonText="Create Poll"
      title="Create Poll Options"
      onClose={onClose}
      name="new-user-card"
      onSubmit={handleSubmit}
    >
      {pollOptions.map((text, index) => (
        <div key={index} className="modal__poll">
          <textarea
            value={text}
            autoFocus
            className="modal__input textarea"
            placeholder="Poll Response"
            onChange={(e) => handleChange(index, e.target.value)}
          />

          <button
            type="button"
            className="button-icon"
            onClick={() => deleteOption(index)}
          >
            <img className="icon" src={DeleteIcon} alt="delete event" />
          </button>
        </div>
      ))}

      <button
        type="button"
        className="button"
        id="addPollOption"
        value="Add Poll Response Option"
        onClick={addOption}
      >
        Add Poll Response Option
      </button>
      <div className="modal__options">
        <div className="modal__polloptions">
          <input
            type="radio"
            value="radio"
            name="Number of Answers"
            checked={optionNum === "radio"}
            onChange={onOptionChange}
          />
          <label>One Answer</label>
        </div>
        <div className="modal__polloptions">
          <input
            type="radio"
            value="checkbox"
            name="Number of Answers"
            checked={optionNum === "checkbox"}
            onChange={onOptionChange}
          />
          <label>Multiple Answers</label>
        </div>
      </div>

      <p>
        Maximum number of accepted responses:
        <input
          name="Number of Options"
          type="number"
          disabled={optionNum !== "checkbox"}
          onChange={onMax}
        ></input>
      </p>
    </ModalWithForm>
  );
};

export default CreatePollModal;
