import React, { useContext, useEffect, useState } from "react";
import { getCurrentUserRole } from "../../utils/auth";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
// import CurrentDiscussionQuestionContext from "../../contexts/CurrentDiscussionQuestionContext";
import CurrentResponseContext from "../../contexts/CurrentResponseContext";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import { createReply } from "../../utils/api";

// onAddItem refers to handleAddItemSubmit, which is declared in App.js
const CreateNewReplyModal = ({ isOpen, responseId, onClose, handleReplies }) => {

  const currentUser = useContext(CurrentLoggedInUserContext);
  const adminoruser = getCurrentUserRole();

  // declare state for each input field (name, imageUrl, weather)
  const [replyText, setReplyText] = useState("");
  const [replierUsername, setReplierUsername] = useState("");
  const [replierAffil, setReplierAffil] = useState("");
  const [replierAvatar, setReplierAvatar] = useState("");
  const [replyLikes, setReplyLikes] = useState([]);
  const [replyDislikes, setReplyDislikes] = useState([]);

  useEffect(() => {
    setReplyText("");
    setReplierUsername("");
    setReplierAffil("");
    setReplierAvatar("");
    setReplyLikes([]);
    setReplyDislikes([]);
  }, [isOpen]);

  function handleReplyTextChange(evt) {
    setReplyText(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();

    console.log("this is the response Id", responseId);

    createReply(responseId, {
      replierUsername,
      replierAffil,
      replierAvatar,
      replyLikes,
      replyDislikes,
      replyText
    }).then((res) => {
      handleReplies(res)
      onClose();
    });
  }

  return (
    <ModalWithForm
      buttonText="Post New Reply"
      title="Create New Reply"
      onClose={onClose}
      name="new-event-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Reply
        <textarea
          autoFocus
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Reply"
          value={replyText}
          onChange={handleReplyTextChange}
          required
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default CreateNewReplyModal;
