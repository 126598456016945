import React, { useContext, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import CurrentEventContext from "../../contexts/CurrentEventContext"; // onAddItem refers to handleAddItemSubmit, which is declared in App.js

// onAddItem refers to handleAddItemSubmit, which is declared in App.js
const CreateNewTopicModal = ({ isOpen, onAddTopic, onClose }) => {
  const currentEvent = useContext(CurrentEventContext);
  const [topicName, setTopicName] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [topicDiscussionQuestions, setTopicDiscussionQuestions] = useState([]);
  const [topicResources, setTopicResources] = useState([]);
  const [topicLaunchStatus, setTopicLaunchStatus] = useState("prelaunch");

  useEffect(() => {
    setTopicName("");
    setTopicDescription("");
    setTopicDiscussionQuestions([]);
    setTopicResources([]);
    setTopicLaunchStatus("prelaunch");
  }, [isOpen]);

  function handleTopicNameChange(evt) {
    setTopicName(evt.target.value);
  }

  function handleTopicDescriptionChange(evt) {
    setTopicDescription(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const topicinfo = {};
    topicinfo.topicname = topicName;
    topicinfo.topicdescription = topicDescription;
    topicinfo.topicquestions = topicDiscussionQuestions;
    topicinfo.topicresources = topicResources;
    topicinfo.topicstatus = topicLaunchStatus;
    onAddTopic(topicinfo, currentEvent);
  }

  return (
    <ModalWithForm
      buttonText="Create New Topic"
      title="Create New Topic"
      onClose={onClose}
      name="new-topic-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Topic Name*
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          maxLength="128"
          placeholder="Name"
          value={topicName}
          onChange={handleTopicNameChange}
          required
        />
      </label>

      <label className="modal__label" id="modal-namelabel">
        Topic Description*
        <textarea
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Description"
          value={topicDescription}
          onChange={handleTopicDescriptionChange}
          required
        />
      </label>

      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default CreateNewTopicModal;
