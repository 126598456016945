import "./AdminEventPageMain.css";
import React, { useCallback, useEffect, useState } from "react";

import TopicCard from "../TopicCard/TopicCard";
import EventResourceCard from "../EventResourceCard/EventResourceCard";
import {
  addEventResource,
  addEventTopic,
  getEvent,
  getEventResources,
  getEventTopics,
} from "../../utils/api";
import { useParams } from "react-router-dom";
import CreateNewTopicModal from "../CreateNewTopicModal/CreateNewTopicModal";
import CreateNewEventResourceModal from "../CreateNewEventResourceModal/CreateNewEventResourceModal";

function AdminEventPageMain({
  event,
  openInviteUserModal,
  openResourcesModal,
}) {
  const [currentEventTopics, setCurrentEventTopic] = useState([]);
  const [selectedEventTopics, setSelectedEventTopics] = useState([]);
  const [selectedEventResources, setSelectedEventResources] = useState([]);
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [newTopicModal, setNewTopicModal] = useState(false);
  const [newEventResourceModal, setNewEventResourceModal] = useState(false);

  const params = useParams();

  let disabled = event.eventlaunchstate === "closed";

  const handleAddTopicSubmit = (values) => {
    addEventTopic({
      eventid: params.eventId,
      eventtopics: values,
    }).then(() => {
      setLoadAttempt(false);
      setNewTopicModal(false);
    });
  };

  let handleCreateNewTopic = () => {
    setNewTopicModal(true);
  };

  const reload = useCallback(() => {
    setLoadAttempt(false);
  }, []);

  const handleAddNewEventResource = (values) => {
    addEventResource({
      eventid: event._id,
      eventresources: values,
    })
      .then(() => {
        setNewEventResourceModal(false);
        setLoadAttempt(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (params.eventId && !loadAttempt) {
      getEvent({ id: params.eventId })
        .then((event) => {
          let eventTopics = event.eventtopics;
          getEventTopics({ eventidarray: eventTopics }).then((eventTopics) => {
            setCurrentEventTopic(eventTopics);
          });
          getEventResources({ eventidarray: event.eventresources }).then(
            (eventResources) => {
              setSelectedEventResources(eventResources);
            },
          );
        })
        .finally(() => {
          setLoadAttempt(true);
        });
    }
  }, [params, currentEventTopics, loadAttempt]);
  return (
    <main className="main">
      <div className="main__admin-forms">
        <button
          className="button button-stretch"
          type="button"
          disabled={disabled}
          onClick={openInviteUserModal}
        >
          MANAGE EVENT ATTENDEES
        </button>
      </div>
      <div className="main__admin-forms">
        <button
          className="button button-stretch"
          type="button"
          disabled={disabled}
          onClick={openResourcesModal}
        >
          MANAGE RESOURCES
        </button>
      </div>
      <>
        <div className="main__page-section">
          <h2 className="main__headers">Event Resources</h2>
          <button
            type="button"
            className="button button-stretch"
            onClick={() => setNewEventResourceModal(true)}
          >
            CREATE NEW EVENT RESOURCE
          </button>
          <section className="main__card-section" id="card-section">
            {selectedEventResources?.map((resource) => (
              <EventResourceCard
                key={resource._id}
                item={resource}
                event={event}
                reload={reload}
              />
            ))}
          </section>
        </div>
      </>
      <>
        <div className="main__page-section">
          <h2 className="main__headers">Event Topics</h2>
          <button
            onClick={handleCreateNewTopic}
            type="button"
            className="button button-stretch"
          >
            CREATE NEW TOPIC
          </button>
          <section className="main__card-section" id="card-section">
            {currentEventTopics?.map((topic) => (
              <TopicCard
                key={topic._id}
                topic={topic}
                event={event}
                reload={reload}
              />
            ))}
          </section>
        </div>
      </>
      {newTopicModal && (
        <CreateNewTopicModal
          onAddTopic={handleAddTopicSubmit}
          setSelectedEventTopics={setSelectedEventTopics}
          selectedEventTopics={selectedEventTopics}
          onClose={() => {
            setNewTopicModal(false);
          }}
        />
      )}
      {newEventResourceModal && (
        <CreateNewEventResourceModal
          onAddItem={handleAddNewEventResource}
          onClose={() => {
            setNewEventResourceModal(false);
          }}
        />
      )}
    </main>
  );
}

export default AdminEventPageMain;
