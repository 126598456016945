import "./AdminEventPage.css";

import React, { useCallback, useContext, useEffect, useState } from "react";

import EventPageHeader from "../EventPageHeader/EventPageHeader";
import AdminEventPageMain from "../AdminEventPageMain/AdminEventPageMain";

import { useParams } from "react-router-dom";
import { getEvent } from "../../utils/api";
import InviteUserModal from "../InviteUserModal/InviteUserModal";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import RemoveUsersPrelaunchModal from "../RemoveUsersPrelaunchModal/RemoveUsersPrelaunchModal";
import AddEventToUserProfileModal from "../AddEventToUserProfileModal/AddEventToUserProfileModal";
import CurrentEventContext from "../../contexts/CurrentEventContext";
import ResourcesModal from "../ResourcesModal/ResourcesModal";

function AdminEventPage() {
  const { handleAddEventToContext } = useContext(CurrentEventContext);
  const [event, setEvent] = useState({});
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [resourcesModal, setResourcesModal] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [addEventToUserProfileModal, setAddEventToUserProfileModal] =
    useState(false);
  const params = useParams();
  const [
    removeInvitedUsersPrelaunchModal,
    setRemoveInvitedUsersPrelaunchModal,
  ] = useState(false);
  const currentUser = useContext(CurrentLoggedInUserContext);
  const openInviteUserModal = () => {
    setInviteUserModal(true);
  };
  const handleCloseModal = () => {
    setInviteUserModal(false);
    setResourcesModal(false);
    reloadEvent();
  };

  const openResourcesModal = () => {
    setResourcesModal(true);
  };

  const handleGetEvent = useCallback(() => {
    return getEvent({ id: params.eventId }).then((updatedEventCard) => {
      setEvent(updatedEventCard);
      return Promise.resolve(updatedEventCard);
    });
  }, [params, setEvent]);

  const handleRemoveUserModalClose = () => {
    setRemoveInvitedUsersPrelaunchModal(false);
  };
  let handleAddEventToUserProfileModalClose = () => {
    setAddEventToUserProfileModal(false);
  };

  const reloadEvent = useCallback(() => {
    setLoadAttempt(false);
  }, []);

  useEffect(() => {
    if (!loadAttempt && params.eventId) {
      getEvent({ id: params.eventId }).then((eventResponse) => {
        setEvent(eventResponse);
        setLoadAttempt(true);
        handleAddEventToContext(params.eventId);
      });
    }
  }, [handleAddEventToContext, loadAttempt, params.eventId]);
  return (
    <>
      <EventPageHeader event={event} reloadEvent={reloadEvent} />
      <AdminEventPageMain
        event={event}
        openInviteUserModal={openInviteUserModal}
        openResourcesModal={openResourcesModal}
      />
      {inviteUserModal && (
        <InviteUserModal
          event={event}
          onClose={handleCloseModal}
          handleGetEvent={handleGetEvent}
        />
      )}
      {resourcesModal && <ResourcesModal onClose={handleCloseModal} />}
      {removeInvitedUsersPrelaunchModal && (
        <RemoveUsersPrelaunchModal
          selectedCardInfo={event}
          invitedUsers={invitedUsers}
          setInvitedUsers={setInvitedUsers}
          userinfodata={currentUser || []}
          onClose={handleRemoveUserModalClose}
        />
      )}
      {addEventToUserProfileModal && (
        <AddEventToUserProfileModal
          userinfodata={currentUser || []}
          onClose={handleAddEventToUserProfileModalClose}
        />
      )}
    </>
  );
}

export default AdminEventPage;
