import React, { useContext, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import CurrentEventContext from "../../contexts/CurrentEventContext";
import CurrentTopicContext from "../../contexts/CurrentTopicContext"; // onAddItem refers to handleAddItemSubmit, which is declared in App.js

// onAddItem refers to handleAddItemSubmit, which is declared in App.js
const CreateNewDiscussionQuestionModal = ({
  isOpen,
  onAddDiscussionQuestion,
  onClose,
  switchToThoughtQuestionsModal,
  switchToFollowUpQuestionsModal,
}) => {
  const currentEvent = useContext(CurrentEventContext);
  const currentTopic = useContext(CurrentTopicContext);
  // declare state for each input field (name, imageUrl, weather)
  const [discussionQuestion, setDiscussionQuestion] = useState("");
  const [discussionQuestionStatus, setDiscussionQuestionStatus] =
    useState("closed");
  const [discussionQuestionBoard, setDiscussionQuestionBoard] = useState([]);
  //   const [topicDescription, setTopicDescription] = useState("");
  //   const [topicDiscussionQuestions, setTopicDiscussionQuestions] = useState([]);
  //   const [topicResources, setTopicResources] = useState([]);

  // use a useEffect hook to reset the input field state to empty strings when
  // the modal is opened (isOpen from input)
  useEffect(() => {
    // setUniqueEventID("");
    setDiscussionQuestion("");
    setDiscussionQuestionStatus("closed");
    setDiscussionQuestionBoard([]);
  }, [isOpen]);

  // create onChange handlers corresponding to each state variable (for each setXxx)

  function handleDiscussionQuestinoChange(evt) {
    setDiscussionQuestion(evt.target.value);
  }

  //   function handleTopicDescriptionChange(evt) {
  //     setTopicDescription(evt.target.value);
  //   }

  function handleSubmit(evt) {
    // prevent default behavior
    evt.preventDefault();
    // call onAddItem with appropriate arguments - handleAddItemSubmit
    const discussionquestioninfo = {};
    discussionquestioninfo.eventtopicdiscussionquestion = discussionQuestion;
    discussionquestioninfo.eventtopicdiscussionquestionstatus =
      discussionQuestionStatus;
    discussionquestioninfo.eventdiscussionquestionresponses =
      discussionQuestionBoard;
    onAddDiscussionQuestion(discussionquestioninfo, currentEvent, currentTopic);
  }

  return (
    <ModalWithForm
      buttonText="Add New Discussion Question"
      title="Add New Discussion Question"
      onClose={onClose}
      name="new-discussionquestion-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Discussion Question
        <textarea
          autoFocus
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="Discussion Question"
          minLength="1"
          placeholder="New Discussion Question"
          value={discussionQuestion}
          onChange={handleDiscussionQuestinoChange}
          required
        />
      </label>

      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default CreateNewDiscussionQuestionModal;
