import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

// onAddItem refers to handleAddItemSubmit, which is declared in App.js
const CreateNewEventModal = ({ onAddItem, onClose }) => {
  // declare state for each input field (name, imageUrl, weather)
  const [eventName, setEventName] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [welcomeMessageVideo, setWelcomeMessageVideo] = useState("");
  const [eventAgenda, setEventAgenda] = useState("");
  const [eventLogo, setEventLogo] = useState("");
  const [launchState, setLaunchState] = useState("prelaunch");
  const [eventInvitedUsers, setEventInvitedUsers] = useState([]);
  const [eventTopics, setEventTopics] = useState([]);
  const [eventResources, setEventResources] = useState([]);

  useEffect(() => {
    setEventName("");
    setEventStartDate("");
    setEventEndDate("");
    setWelcomeMessage("");
    setWelcomeMessageVideo("");
    setEventAgenda("");
    setEventLogo("");
    setLaunchState("prelaunch");
    setEventInvitedUsers([]);
    setEventTopics([]);
    setEventResources([]);
  }, []);

  function handleEventNameChange(evt) {
    setEventName(evt.target.value);
  }

  function handleEventStartDateChange(evt) {
    setEventStartDate(evt.target.value);
  }

  function handleEventEndDateChange(evt) {
    setEventEndDate(evt.target.value);
  }

  function handleWelcomeMessageChange(evt) {
    setWelcomeMessage(evt.target.value);
  }

  function handleEventAgendaChange(evt) {
    setEventAgenda(evt.target.value);
  }

  function handleEventLogoChange(evt) {
    const acceptedFile = evt.target.files;
    const file = acceptedFile.length === 1 && acceptedFile[0];
    setEventLogo(
      file &&
        Object.assign(file, {
          url: URL.createObjectURL(file),
        })
    );
  }

  function handleWelcomeMessageVideoChange(evt) {
    setWelcomeMessageVideo(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const eventinfo = {};
    eventinfo.eventname = eventName;
    eventinfo.eventstartdate = eventStartDate;
    eventinfo.eventenddate = eventEndDate;
    eventinfo.eventwelcomemessage = welcomeMessage;
    eventinfo.eventwelcomevideo = welcomeMessageVideo;
    eventinfo.eventagenda = eventAgenda;
    eventinfo.eventlogo = eventLogo;
    eventinfo.eventlaunchstate = launchState;
    eventinfo.eventinvitedusers = eventInvitedUsers;
    eventinfo.eventtopics = eventTopics;
    eventinfo.eventresources = eventResources;
    onAddItem(eventinfo);
  }

  return (
    <ModalWithForm
      buttonText="Create New Event"
      title="Create New Event"
      onClose={onClose}
      name="new-event-card"
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Event Name*
        <input
          autoFocus
          className="modal__input"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          maxLength="128"
          placeholder="Name"
          value={eventName}
          onChange={handleEventNameChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-imagelabel">
        Event Start Date*
        <input
          className="modal__input"
          id="modal-link"
          type="date"
          name="event_dates"
          placeholder="Date"
          value={eventStartDate}
          onChange={handleEventStartDateChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-imagelabel">
        Event End Date*
        <input
          className="modal__input "
          id="modal-link"
          type="date"
          name="event_dates"
          placeholder="Date"
          value={eventEndDate}
          onChange={handleEventEndDateChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        Welcome Message*
        <textarea
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          //   maxLength="30"
          placeholder="Welcome Message"
          value={welcomeMessage}
          onChange={handleWelcomeMessageChange}
          required
        />
      </label>
      <label className="modal__label" id="modal-namelabel">
        Welcome Video (Optional)
        <input
          className="modal__input"
          id="modal-name"
          type="text"
          name="welcomevideo"
          minLength="1"
          //   maxLength="30"
          placeholder="Welcome Video URL"
          value={welcomeMessageVideo}
          onChange={handleWelcomeMessageVideoChange}
        />
      </label>
      {/* <label className="modal__label" id="modal-namelabel">
        Agenda*
        <textarea
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          //   maxLength="30"
          placeholder="Agenda"
          value={eventAgenda}
          onChange={handleEventAgendaChange}
          required
        />
      </label> */}
      <label className="modal__label " id="modal-imagelabel">
        Event Logo* (.png/.jpeg)
        <input
          className="modal__input file-input"
          id="modal-link"
          type="file"
          accept="image/png, image/jpeg"
          name="link"
          placeholder="Avatar Image"
          onChange={handleEventLogoChange}
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default CreateNewEventModal;
