import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import {
  deleteEventResource,
  deleteTopicResource,
  allResources,
} from "../../utils/api";
import DeleteIcon from "../../images/DeleteIcon.svg";

import "./ResourcesModal.css";
import { useParams } from "react-router-dom";

const ResourcesModal = ({ onClose, event, handleGetEvent }) => {
  const params = useParams();

  const [eventResources, setEventResources] = useState([]);
  const [topicResources, setTopicResources] = useState([]);
  const [topicIds, setTopicIds] = useState({});
  console.log(topicResources);

  const handleEventResourceDelete = (item) => {
    deleteEventResource({
      eventid: params.eventId,
      resourceid: item._id,
    }).then(() => {
      getAllResources();
    });
  };

  const getAllResources = () =>
    allResources(params.eventId).then((res) => {
      console.log(res);
      setEventResources(res.resourcesByEvent);
      setTopicResources(res.resourcesByTopic);
      if (res.topicsObj) {
        setTopicIds(res.topicsObj);
      }
    });

  const handleTopicResourceDelete = (item) => {
    console.log(item._id);
    console.log(topicIds[item._id]);
    deleteTopicResource({
      topicid: topicIds[item._id],
      resourceid: item._id,
    })
      .then(() => {
        getAllResources();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (params && params.eventId) {
      getAllResources();
    }
  }, []);

  return (
    <ModalWithForm
      buttonText=""
      title="Manage Resources"
      onClose={onClose}
      onSubmit={() => {}}
    >
      <div>
        {eventResources.length !== 0 ? (
          <table className="table">
            <tr>
              <th className="table__headers">Event Resource Name</th>
              <th className="table__headers">Type</th>
              <th className="table__headers">Delete</th>
            </tr>

            {eventResources &&
              eventResources.map((r) => (
                <tr>
                  <td className="table__values">
                    {r?.contentName || r?.eventresourcename}
                  </td>
                  <td className="table__values table__center">
                    {r?.contentType === "application/pdf" ? "PDF" : "Video"}
                  </td>
                  <td className="table__values table__center">
                    <button
                      type="button"
                      className="button-icon"
                      onClick={() => handleEventResourceDelete(r)}
                    >
                      <img
                        className="icon"
                        src={DeleteIcon}
                        alt="delete event"
                      />
                    </button>
                  </td>
                </tr>
              ))}
          </table>
        ) : (
          <div className="box">No Event Resources</div>
        )}
      </div>
      <div>
        {topicResources.length !== 0 ? (
          <table className="table">
            <tr>
              <th className="table__headers">Topic Resource Name</th>
              <th className="table__headers">Type</th>
              <th className="table__headers">Delete</th>
            </tr>

            {topicResources &&
              topicResources.map((r) => (
                <tr>
                  <td className="table__values">
                    {r?.contentName || r?.eventtopicresourcename}
                  </td>
                  <td className="table__values table__center">
                    {r?.contentType === "application/pdf" ? "PDF" : "Video"}
                  </td>
                  <td className="table__values table__center">
                    <button
                      type="button"
                      className="button-icon"
                      onClick={() => handleTopicResourceDelete(r)}
                    >
                      <img
                        className="icon"
                        src={DeleteIcon}
                        alt="delete event"
                      />
                    </button>
                  </td>
                </tr>
              ))}
          </table>
        ) : (
          <div className="box">No Topic Resources</div>
        )}
      </div>
    </ModalWithForm>
  );
};

export default ResourcesModal;
