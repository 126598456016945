import React, { useCallback, useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import EmailResponseModal from "../EmailResponseModal/EmailResponseModal";
import {
  activateUsers,
  deactivateUsers,
  forgotPassword,
  getUsers,
  searchByUser,
} from "../../utils/api";

const ManageUsersModal = ({
  isOpen,
  onClose,
  setSelectedUser,
  handleEditUserInfo,
}) => {
  const [users, setUsers] = useState([]);
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [checkedAll, setCheckedAll] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [emailResponseModal, setEmailResponseModal] = useState(false);
  const [PostMarkResponse, setPostMarkResponse] = useState("");

  const handleSelectAll = useCallback(() => {
    if (checkedAll.length !== users.length) {
      setCheckedAll(users.map((user) => user._id));
      setSelected(users.map((user) => user._id));
    }
    if (checkedAll.length === users.length) {
      setCheckedAll([]);
      setSelected([]);
    }
  }, [checkedAll, users, setCheckedAll]);

  const handleSelect = useCallback(
    (e) => {
      const value = e.target.value;
      if (selected.includes(value)) {
        setSelected(selected.filter((x) => x !== value));
      } else {
        const newSelected = [...selected, value];
        setSelected(newSelected);
        setSelectedUser(newSelected);
      }
    },
    [selected, setSelectedUser]
  );

  function handleSubmit(evt) {
    evt.preventDefault();
  }

  function handleGetUsers() {
    getUsers().then((res) => {
      setUsers(res);
      setSelected([]);
      setLoadAttempt(true);
    });
  }

  useEffect(() => {
    if (!loadAttempt) {
      handleGetUsers();
    }
    if (selected.length > 0 && selected.length === users.length) {
      setCheckedAll(users.map((i) => i._id));
    } else if (selected.length !== users.length) {
      setCheckedAll([]);
    }
  }, [users, selected, setCheckedAll, loadAttempt, setLoadAttempt]);
  console.log(selected);

  function handleDeactivateUser() {
    deactivateUsers(selected).then((response) => {
      console.log(response);
      handleGetUsers();
    });
  }

  function handleActivateUser() {
    activateUsers(selected).then((response) => {
      console.log(response);
      handleGetUsers();
    });
  }

  function handleForgotPassword() {
    forgotPassword(selected).then((response) => {
      console.log(response);
      setPostMarkResponse(response);
      handleGetUsers();
    });
  }

  function handleSearch(e) {
    e.preventDefault();
    console.log(e.target.value);
    const searchWord = e.target.value;
    setSearch(searchWord);
    if (searchWord.length > 3) {
      searchByUser(searchWord).then((res) => {
        setSearchResults(res);
      });
    }
  }

  return (
    <ModalWithForm
      buttonText=""
      title="User Management"
      onClose={onClose}
      name="new-user-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <div className="search-anchor">
        <div className="button-group">
          <button
            className="button"
            type="button"
            onClick={handleEditUserInfo}
            disabled={selected.length !== 1}
          >
            Edit User Info
          </button>
          <button
            className="button"
            type="button"
            onClick={handleDeactivateUser}
            disabled={!selected.length > 0}
          >
            Set to Inactive
          </button>
          <button
            className="button"
            type="button"
            onClick={handleActivateUser}
            disabled={!selected.length > 0}
          >
            Set to Active
          </button>
          <button
            className="button"
            type="button"
            onClick={() => {
              handleForgotPassword();
              setEmailResponseModal(true);
            }}
            disabled={selected.length !== 1}
          >
            Forgot Password
          </button>
          <input
            className="search__input"
            type="text"
            placeholder="Search by user name or email"
            value={search}
            onChange={handleSearch}
          />
          {searchResults.length > 0 && (
            <div className="search-results">
              {searchResults.map((result) => (
                <div
                  className="search-result"
                  onClick={() => setUsers([result])}
                >
                  <div>{result.username}</div>
                  <div>{result.useremail}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {emailResponseModal && (
        <EmailResponseModal
          // emailStatus={PostMarkResponse}
          onClose={() => {
            setEmailResponseModal(false);
          }}
        />
      )}

      {users.length !== 0 ? (
        <table className="table">
          <tr>
            <td>
              <input
                type="checkbox"
                checked={checkedAll.length === users.length}
                onClick={handleSelectAll}
              />
            </td>
            <th className="table__headers">Email</th>
            <th className="table__headers">User Name</th>
            <th className="table__headers">User Affiliation</th>
            <th className="table__headers">Role</th>
            <th className="table__headers">Status</th>
          </tr>

          {users &&
            users.map((user) => (
              <tr>
                <td>
                  <input
                    type="checkbox"
                    value={user._id}
                    checked={selected.includes(user._id)}
                    onClick={handleSelect}
                  />
                </td>
                <td className="table__values"> {user.useremail}</td>
                <td className="table__values">
                  {user.firstName} {user.lastName}
                </td>
                <td className="table__values">
                  <div className="truncation">{user.useraffiliation}</div>
                </td>
                <td className="table__values"> {user.userrole}</td>
                <td className="table__values"> {user.userstatus}</td>
              </tr>
            ))}
        </table>
      ) : (
        <div className="box">No Users available</div>
      )}

      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default ManageUsersModal;
