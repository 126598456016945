import React from "react";

const DeleteResponseModal = ({ handleResponseDelete, onClose }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to delete this response?
      </p>
      <button className="button button-error" onClick={handleResponseDelete}>
        Yes, delete response
      </button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default DeleteResponseModal;
