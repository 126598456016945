import React from "react";

const ConfirmClosePollModal = ({ onClose, onConfirm }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to close this poll?
      </p>
      <button className="button button-error" onClick={onConfirm}>Yes, close poll</button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default ConfirmClosePollModal;
