import React from "react";

const WelcomeVideoModal = ({ onClose }) => {
  return (
    <div className={`modal`}>
      <div className="modal__container-pdf">
        <div className="modal__subcontainer">
          <div className="card__text-title">Add event name</div>
          <button
            className="modal__popup-close"
            type="button"
            onClick={onClose}
          ></button>
        </div>
        <video className="modal__videoresource" source="" type="" controls>
          Video here
        </video>
      </div>
    </div>
  );
};

export default WelcomeVideoModal;
