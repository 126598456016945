// import LogoutModal from "../LogoutModal/LogoutModal";
import React from "react";

const DeleteEventResourceModal = ({ handleEventResourceDelete, onClose }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to delete this event resource?
      </p>
      <button
        className="button button-error"
        onClick={handleEventResourceDelete}
      >
        Yes, delete event resource
      </button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default DeleteEventResourceModal;
