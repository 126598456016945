import "./TopicPageHeader.css";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import CurrentEventContext from "../../contexts/CurrentEventContext";
import BC_Logo from "../../images/BC_Logo.svg";
import Navbar from "../Navbar/Navbar";
import {
  editSelectedTopic,
  getEventByTopicId,
  getEventTopic,
} from "../../utils/api";
import { useParams } from "react-router-dom";
import EventActionButtons from "../EventActionButtons/EventActionButtons";
import EditTopicModal from "../EditTopicModal/EditTopicModal";
import { isUserAdmin } from "../../utils/auth";
import RenderLogo from "../RenderLogo";

function TopicPageHeader() {
  const params = useParams();
  const isAdmin = isUserAdmin();
  const [loadAttempt, setLoadAttempt] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [currentTopic, setCurrentTopic] = useState({});
  const [editTopicModal, setEditTopicModal] = useState(false);
  const { selectedEventCard } = useContext(CurrentEventContext);
  const currentEventName = currentEvent?.eventname;
  const currentEventStartDate = currentEvent?.eventstartdate;
  const currentEventEndDate = currentEvent?.eventenddate;

  const currentTopicName = currentTopic?.topicname || "";
  const currentTopicDescription = currentTopic?.topicdescription || "";
  const currentTopicLaunchState = currentTopic?.topicstatus || "";

  const event_startdate = dayjs(currentEventStartDate).format("MM-DD-YYYY");
  const event_enddate = dayjs(currentEventEndDate).format("MM-DD-YYYY");

  const link = {
    text: "Profile",
    path: isAdmin ? "/adminprofile" : "/profile",
    text2: "Event",
    path2: isAdmin
      ? `/adminevent/${currentEvent._id}`
      : `/event/${currentEvent._id}`,
  };
  const handleEditTopicSubmit = (values) => {
    editSelectedTopic({
      topicid: currentTopic._id,
      updatedtopicinfo: values,
    })
      .then(() => {
        setEditTopicModal(false);
        setLoadAttempt(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!loadAttempt) {
      getEventTopic(params.id)
        .then((topic) => {
          setCurrentTopic(topic);
          getEventByTopicId(params.id).then((eventResponse) => {
            setCurrentEvent(eventResponse);
            setLoadAttempt(true);
          });
        })
        .finally(() => {
          setLoadAttempt(true);
        });
    }
  }, [loadAttempt, params.id, selectedEventCard]);

  return (
    <>
      <div className="bc__logo-container">
        <Link to={link.path}><img className="bc__logo" src={BC_Logo} alt="BC_logo" /></Link>
        <Navbar link={link} />
      </div>
      <header className="pageheader">
        <div className="pageheader__info-container">
          <div className="pageheader__subheader1-container">
            <div className="pageheader__subheader2-container">
              <p className="card__text-title"> Event </p>
              <p className="card__text-date">{currentEventName}</p>
              <p className="card__text-title">Event Dates</p>
              <p className="card__text-date">
                {event_startdate} TO {event_enddate}
              </p>
            </div>
            <div className="pageheader__statusicons-container">
              <RenderLogo
                imageContent={currentEvent?.imageContent}
                classes="pageheader__event-logo"
              />
              {isAdmin && (
                <p className="card__text-status"> {currentTopicLaunchState}</p>
              )}
            </div>
          </div>
          <div className="pageheader__line"></div>
          <div className="pageheader__info_container">
            <div className="pageheader__info-container-row">
              <div className="pageheader__info-container-col">
                <p className="card__text-title">Topic Name</p>
                <p className="card__text-date">{currentTopicName}</p>
              </div>
            </div>
            <div className="pageheader__info-container-row">
              <div className="pageheader__info-container-col">
                <p className="card__text-title">Topic Description</p>
                <p className="card__text-date">{currentTopicDescription}</p>
              </div>
              <div>
                {isAdmin && (
                  <EventActionButtons
                    id={currentTopic._id}
                    state={currentTopic.topicstatus}
                    type="topic"
                    openModal={() => {
                      setEditTopicModal(true);
                    }}
                    reload={() => setLoadAttempt(false)}
                  />
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </header>
      {editTopicModal && (
        <EditTopicModal
          currentTopic={currentTopic}
          onEditTopic={handleEditTopicSubmit}
          onClose={() => {
            setEditTopicModal(false);
          }}
        />
      )}
    </>
  );
}

export default TopicPageHeader;
