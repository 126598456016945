import React, { useContext } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

import CurrentEventContext from "../../contexts/CurrentEventContext";
import CurrentUserContext from "../../contexts/CurrentUserContext";

const ManageInviteUserModal = ({
  selectedUser,
  isOpen,
  closeModal,
  onClose,
  handleAddSelectedEventToUserProfile,
  handleOpenAddEventConfirmationModal,
}) => {
  const currentEvent = useContext(CurrentEventContext);
  const currentUserContext = useContext(CurrentUserContext);
  //   console.log(selectedUser.userinvitedevents);
  const userEventList = currentUserContext.userinvitedevents;

  const checker = userEventList.some(
    (listedevent) => listedevent.eventid === currentEvent._id,
  );

  function handleSubmit(evt) {
    // prevent default behavior
    evt.preventDefault();
    onClose();
  }

  return (
    <ModalWithForm
      buttonText="Close Invite User Management"
      title={"Invite User Management for:" + selectedUser.username}
      onClose={onClose}
      name="new-user-card"
      isOpen={isOpen}
      onSubmit={handleSubmit} //closes the modal only - no other actions - TO FIX
    >
      {!checker ? (
        <button
          className="button"
          type="button"
          //   disabled={disableButton ? true : false}
          onClick={() => {
            handleAddSelectedEventToUserProfile();
            handleOpenAddEventConfirmationModal();
          }}
        >
          Add Event to User Profile
        </button>
      ) : (
        <p>Event added to User Profile</p>
      )}
      <button
        className="button"
        type="button"
        //   disabled={disableButton ? true : false}
        onClick={() => {
          //   onSelectCard(eventinfo);
          //   handleDeleteEvent();
        }}
      >
        Send User Invitiation Email
      </button>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};

export default ManageInviteUserModal;
