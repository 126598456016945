//Item Modal component
import React, { useState } from "react";
import "./EventResourceCardModal.css";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const EventResourceCardModal = ({ onClose, file }) => {
  const pdforvideo = "pdf";

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <div className={`modal modal_scroll`}>
      <div className="modal__container-pdf">
        <div className="modal__subcontainer">
          <div className="card__text-title">{file.contentName}</div>
          <button
            className="modal__popup-close"
            type="button"
            onClick={onClose}
          ></button>
        </div>
        {pdforvideo ? (
          <div>
            <Document
              className="modal__pdfresource"
              file={`data:pdf;base64,${file.pdfContent}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </div>
        ) : (
          <video className="modal__videoresource" source="" type="" controls>
            Video here
          </video>
        )}
      </div>
    </div>
  );
};

export default EventResourceCardModal;
