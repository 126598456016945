import React from "react";

export const TYPE_MAP = {
  event: "event",
  topic: "topic",
  discussionQuestion: "discussion question",
};

const ConfirmCloseModal = ({ handleClose, onClose, type }) => {
  return (
    <div className="modal">
      <div className="modal__content">
        <p className="modal__confirm-message">
          Are you sure you want to close this {TYPE_MAP[type]}?
        </p>
        <p className="modal__confirm-message">
          <button className="button button-error" onClick={handleClose}>
            Yes, close {TYPE_MAP[type]}
          </button>
        </p>
        <p className="modal__confirm-cancel" onClick={onClose}>
          Cancel
        </p>
      </div>
    </div>
  );
};

export default ConfirmCloseModal;
