import React, { useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";
import { editReply } from "../../utils/api";

const EditReplyModal = ({ isOpen, onClose, item, handleSocketAndReload }) => {
  const currentReplyText = item.replyText;

  const [updatedReply, setUpdatedReply] = useState(null);

  function handleReplyTextChange(evt) {
    setUpdatedReply(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    editReply(item._id, updatedReply).then(() => {
      handleSocketAndReload();
      onClose()
    });
  }

  // Update Profile form with one button to submit
  return (
    <ModalWithForm
      buttonText="Update Reply"
      title="Edit Reply"
      onClose={onClose}
      name="new-reply-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Reply
        <textarea
          autoFocus
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          placeholder="Reply"
          value={updatedReply || currentReplyText}
          onChange={handleReplyTextChange}
          required
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};
export default EditReplyModal;
