import React, { useCallback } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import DownloadIcon from "../images/file_download.svg";
import { useParams } from "react-router-dom";
import {getAggregatePoll, getDiscussionReport, getPollByUsers} from "../utils/api";

const ExcelExport = ({ excelData, excelData2, fileName, getResponses }) => {
  const params = useParams();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = useCallback(async () => {

    getDiscussionReport(params.id).then(async (res) => {
      const {
        replies,
        responses,
        replyUsers,
        responseUsers,
        eventdiscussionquestionresponses,
        event,
        eventTopic,
        allUsers,
        ...rest
      } = res;

      const aggregatePoll = await getAggregatePoll(params.id).then((x) => Promise.resolve(x));
      const pollDetails = await getPollByUsers(params.id).then((x) => Promise.resolve(x));
      const eventName = event.eventname;
      const eventID = event._id;
      const eventTopicName = eventTopic.topicname;
      const eventTopicID = eventTopic._id;

      const dqdata = {
        ...rest,
        eventTopicName,
        eventTopicID,
        eventName,
        eventID,
      };


      const uniqueUserList = allUsers;


      const ws0 = XLSX.utils.json_to_sheet([dqdata]);
      const ws1 = XLSX.utils.json_to_sheet(replies);
      const ws2 = XLSX.utils.json_to_sheet(responses);
      const ws3 = XLSX.utils.json_to_sheet(uniqueUserList);
      const ws4 = XLSX.utils.json_to_sheet(aggregatePoll);
      const ws5 = XLSX.utils.json_to_sheet(pollDetails);
      const wb = {
        Sheets: {
          discussionquestion: ws0,
          responses: ws2,
          replies: ws1,
          users: ws3,
          pollData: ws4,
          pollByUsers: ws5,
        },
        SheetNames: ["discussionquestion", "responses", "replies", "users", 'pollData', 'pollByUsers'],
      };

      const excelBuffer = await XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const data = await new Blob([excelBuffer], { type: fileType });
      await FileSaver.saveAs(data, fileName + fileExtension);
    });
  }, [excelData, excelData2, fileName]);

  return (
    <>
      <button
        type="button"
        className="button-icon button-icon-outline "
        onClick={exportToExcel}
      >
        <img className="icon-enlarge" src={DownloadIcon} alt="delete event" />
      </button>
    </>
  );
};

export default ExcelExport;
