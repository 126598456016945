import React, { useState } from "react";
import DeleteIcon from "../../images/DeleteIcon.svg";
import PDFIcon from "../../images/picture_as_pdf.svg";
import VideoIcon from "../../images/video_file.svg";

import "./TopicResourceCard.css";
import { getCurrentUserRole } from "../../utils/auth";
import { deleteTopicResource } from "../../utils/api";
import DeleteTopicResourceModal from "../DeleteTopicResourceModal/DeleteTopicResourceModal";
import TopicResourceCardModal from "../TopicResourceCardModal/TopicResourceCardModal";

export function NewTab({ videoinfo, pdf }) {
  const videoLink = videoinfo?.includes("https://")
    ? videoinfo.replace("https://", "")
    : videoinfo;
  return (
    <a href={`//${videoLink}`} target="_blank" rel="noopener noreferrer">
      <img
        className="button-icon button-icon-outline icon-file"
        src={pdf ? PDFIcon : VideoIcon}
        alt="Video file"
      />
    </a>
  );
}

const TopicResourceCard = ({
  item, //resource from map
  topic,
  reload,
}) => {
  const adminoruser = getCurrentUserRole();
  const [previewPdf, setPreviewPdf] = useState(false);
  const [deleteTopicResourceModal, setDeleteTopicResourceModal] =
    useState(false);

  const videoinfo = item.eventtopicresourcevideo;

  const handleTopicResourceDelete = () => {
    deleteTopicResource({
      topicid: topic._id,
      resourceid: item._id,
    })
      .then(() => {
        reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="resourcecard">
        <div className="resourcecard-container">
          {videoinfo === "" ? (
            <NewTab videoinfo={item.fileLink} pdf />
          ) : (
            <NewTab videoinfo={videoinfo} />
          )}
          <div className="card__text-title">{item.eventtopicresourcename}</div>
        </div>
        {adminoruser === "admin" ? (
          <div className="resourcecard-icon-container">
            <button
              type="button"
              className="button-icon"
              onClick={() => setDeleteTopicResourceModal(true)}
            >
              <img className="icon" src={DeleteIcon} alt="delete event" />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      {deleteTopicResourceModal && (
        <DeleteTopicResourceModal
          handleTopicResourceDelete={handleTopicResourceDelete}
          onClose={() => setDeleteTopicResourceModal(false)}
        />
      )}
      {previewPdf && (
        <TopicResourceCardModal
          onClose={() => setPreviewPdf(false)}
          file={item}
        />
      )}
    </>
  );
};

export default TopicResourceCard;
