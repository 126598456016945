import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

function ErrorFallback() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <button onClick={handleNavigate}>Return to homepage</button>
    </div>
  );
}

export default ErrorFallback;
