import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

const EditResponseModal = ({
  isOpen,
  onClose,
  onEditResponse,
  currentResponse,
}) => {
  const currentResponseText = currentResponse?.discussionquestionresponse;
  const currentResponderUsername = currentResponse?.responderusername;
  const currentResponderAvatar = currentResponse?.responderavatar;
  const currentResponderAffil = currentResponse?.responderaffiliation;
  const currentResponderReplies = currentResponse?.replies;
  const currentResponderLikes = currentResponse?.likes;
  const currentResponderDislikes = currentResponse?.dislikes;

  const [updatedResponse, setUpdatedResponse] = useState({});

  const [responseText, setResponseText] = useState("");
  // const [responseTimeDate, setResponseTimeDate] = useState("");
  const [responderUsername, setResponderUsername] = useState("");
  const [responderAvatar, setResponderAvatar] = useState("");
  const [responderAffil, setResponderAffil] = useState("");
  const [responseReplies, setResponseReplies] = useState([]);
  const [responseLikes, setResponseLikes] = useState([]);
  const [responseDisLikes, setResponseDislikes] = useState([]);

  useEffect(() => {
    setResponseText(currentResponseText);
    // setResponseTimeDate(currentResponseTimeDate);
    setResponderUsername(currentResponderUsername);
    setResponderAvatar(currentResponderAvatar);
    setResponderAffil(currentResponderAffil);
    setResponseReplies(currentResponderReplies);
    setResponseLikes(currentResponderLikes);
    setResponseDislikes(currentResponderDislikes);
  }, [
    currentResponseText,
    currentResponderUsername,
    currentResponderAvatar,
    currentResponderAffil,
    currentResponderReplies,
    currentResponderLikes,
    currentResponderDislikes,
  ]);

  function handleResponseTextChange(evt) {
    setResponseText(evt.target.value);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const update_response = {};
    update_response.discussionquestionresponse = responseText;
    onEditResponse(update_response);
  }

  return (
    <ModalWithForm
      buttonText="Update Response"
      title="Edit Response"
      onClose={onClose}
      name="new-event-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Response
        <textarea
          autoFocus
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          // maxLength="30"
          placeholder="Name"
          value={responseText}
          onChange={handleResponseTextChange}
          required
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};
export default EditResponseModal;
