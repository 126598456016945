import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
// ...
const Navbar = ({ link }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (navbarOpen && ref.current && !ref.current.contains(event.target)) {
        setNavbarOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
    };
  }, [navbarOpen]);

  const text2info = link.text2 === "Event";
  const text3info = link.text3 === "Topic";

  return (
    <>
      <nav ref={ref} className="navbar">
        <button
          className="toggle"
          onClick={() => setNavbarOpen((prev) => !prev)}
        >
          {navbarOpen ? (
            <MdClose style={{ width: "32px", height: "32px" }} />
          ) : (
            <FiMenu
              style={{
                width: "32px",
                height: "32px",
              }}
            />
          )}
        </button>
        <ul className={`menu-nav${navbarOpen ? " show-menu" : ""}`}>
          <li className="link-item">
            <NavLink
              className="link-text"
              style={{ textDecoration: "none" }}
              to={link.path}
              onClick={() => setNavbarOpen(false)}
            >
              {link.text}
            </NavLink>
          </li>
          <li className="link-item">
            <NavLink
              className={text2info ? "link-text" : "link-disabled"}
              style={{ textDecoration: "none" }}
              to={link.path2}
              onClick={() => setNavbarOpen(false)}
            >
              {text2info ? link.text2 : "Event"}
            </NavLink>
          </li>
          <li className="link-item">
            <NavLink
              className={text3info ? "link-text" : "link-disabled"}
              style={{ textDecoration: "none" }}
              to={link.path3}
              onClick={() => setNavbarOpen(false)}
            >
              {text3info ? link.text3 : "Topic"}
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};
export default Navbar;
