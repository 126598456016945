import EditIcon from "../../images/EditIcon.svg";
import { ACTIVE, CLOSED, PRELAUNCH } from "../../utils/constants";
import LaunchIcon from "../../images/LaunchIcon.svg";
import disableLaunchIcon from "../../images/disableLaunchIcon.svg";
import closeStatusIcon from "../../images/closeStatusIcon.svg";
import disableCloseIcon from "../../images/disableCloseStatusIcon.svg";
import React, { useState } from "react";
import ConfirmCloseModal from "../ConfirmCloseEventModal/ConfirmCloseModal";
import {
  editEventLaunchState,
  launchSelectedDiscussionQuestion,
  launchSelectedTopic,
} from "../../utils/api";
import ConfirmLaunchModal from "../ConfirmEventLaunchModal/ConfirmLaunchModal";

const EventActionButtons = ({
  openModal,
  id,
  state: launchState,
  type,
  reload,
  children,
}) => {
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [confirmLaunchModal, setConfirmLaunchModal] = useState(false);
  const [updatedState, setUpdatedState] = useState(null);

  const state = updatedState !== null ? updatedState : launchState;

  function closeAllModals(action) {
    setConfirmCloseModal(false);
    setConfirmLaunchModal(false);
    setUpdatedState(action);
  }

  function getActionType(action) {
    if (type === "event") {
      return editEventLaunchState({
        eventid: id,
        eventlaunchstate: action,
      });
    }
    if (type === "topic") {
      return launchSelectedTopic({
        topicid: id,
        launchstate: action,
      });
    }
    if (type === "discussionQuestion") {
      return launchSelectedDiscussionQuestion({
        questionid: id,
        launchstate: action,
      });
    }
  }

  const handleAction = (action) => {
    const actionType = getActionType(action);
    actionType
      .then(() => {
        closeAllModals(action);
        reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleConfirmCloseModal = () => {
    setConfirmCloseModal(true);
  };

  return (
    <>
      <div className="pageheader__icon-container">
        <button
          className="button-icon button-icon-outline "
          type="button"
          onClick={openModal}
        >
          <img className="icon-enlarge" src={EditIcon} alt="edit event" />
        </button>
        <button
          className={
            state === PRELAUNCH
              ? "button-icon button-icon-outline"
              : "button-icon button-icon-outline-disabled"
          }
          type="button"
          disabled={state !== PRELAUNCH}
          onClick={() => setConfirmLaunchModal(true)}
        >
          {state === PRELAUNCH ? (
            <img className="icon-enlarge" src={LaunchIcon} alt="event launch" />
          ) : (
            <img
              className="icon-enlarge"
              src={disableLaunchIcon}
              alt="no launch event"
            />
          )}
        </button>
        <button
          className={
            state === ACTIVE
              ? "button-icon button-icon-outline"
              : "button-icon button-icon-outline-disabled"
          }
          type="button"
          disabled={state !== ACTIVE}
          onClick={handleConfirmCloseModal}
        >
          {state === ACTIVE ? (
            <img
              className="icon-enlarge"
              src={closeStatusIcon}
              alt="close event"
            />
          ) : (
            <img
              className="icon-enlarge"
              src={disableCloseIcon}
              alt="no launch event"
            />
          )}
        </button>
        {children}
      </div>
      <div>
        {confirmCloseModal && (
          <ConfirmCloseModal
            handleClose={() => handleAction(CLOSED)}
            onClose={() => {
              setConfirmCloseModal(false);
            }}
            type={type}
          />
        )}
        {confirmLaunchModal && (
          <ConfirmLaunchModal
            handleLaunch={() => handleAction(ACTIVE)}
            onClose={() => {
              setConfirmLaunchModal(false);
            }}
            type={type}
          />
        )}
      </div>
    </>
  );
};

export default EventActionButtons;
