import { baseUrl, getBase64File, getToken } from "./auth";

// const baseUrl = "http://localhost:4000/api";

//Check for server response with promise state
export const checkResponse = (res) => {
  if (res.ok) {
    return res.json();
  } else {
    return Promise.reject(`Error ${res.status}`);
  }
};

// GET Event Items from Database
export const getEventItems = async () => {
  return await fetch(`${baseUrl}/events`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const getEventsByAdmin = async (id) => {
  return await fetch(`${baseUrl}/events/${id}/admin`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
export const getEventsByUser = async (id) => {
  return await fetch(`${baseUrl}/events/${id}/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const rsvpEvent = async (id) => {
  return await fetch(`${baseUrl}/events/${id}/rsvp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
export const declineEvent = async (id) => {
  return await fetch(`${baseUrl}/events/${id}/decline`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

// POST Add New Event Item
export const addEventItem = async ({
  eventname,
  eventstartdate,
  eventenddate,
  eventwelcomemessage,
  eventwelcomevideo,
  eventagenda,
  eventlogo,
  eventlaunchstate,
  eventinvitedusers,
  eventtopics,
  eventresources,
}) => {
  await getBase64File(eventlogo).then((base64File) => {
    const newLogoImage = base64File && base64File.split(",").slice(1).join(",");
    return fetch(`${baseUrl}/events`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: getToken(),
      },
      body: JSON.stringify({
        eventname,
        eventstartdate,
        eventenddate,
        eventwelcomemessage,
        eventwelcomevideo,
        eventagenda,
        contentName: eventlogo.name,
        imageContent: newLogoImage,
        contentType: eventlogo.type,
        eventlaunchstate,
        eventinvitedusers,
        eventtopics,
        eventresources,
      }),
    }).then((res) => checkResponse(res));
  });
};

// PATCH AdminEditUserInfo
export const editProfileInfo = async ({ id, useraffiliation, userstatus }) => {
  return fetch(`${baseUrl}/users/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      useraffiliation,
      userstatus,
    }),
  }).then((res) => checkResponse(res));
};

// GET Users from Database
export const getUsers = () => {
  return fetch(`${baseUrl}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const editUserStatus = ({ id, userstatus }) => {
  return fetch(`${baseUrl}/users/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      userstatus,
    }),
  }).then((res) => checkResponse(res));
};

export const deleteEvent = (eventid) => {
  return fetch(`${baseUrl}/events/${eventid}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

// export const addInvitedUserstoEvent = ({ id, eventinvitedusers }) => {
//   return fetch(`${baseUrl}/events/${id}`, {
//     method: "PATCH",
//     headers: {
//       "Content-Type": "application/json",
//       authorization: getToken(),
//     },
//     body: JSON.stringify({
//       eventinvitedusers,
//     }),
//   }).then((res) => checkResponse(res));
// };

export const deactivateUsers = (users) => {
  return fetch(`${baseUrl}/users/status/inactive`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      users,
    }),
  }).then((res) => checkResponse(res));
};
export const activateUsers = (users) => {
  return fetch(`${baseUrl}/users/status/active`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      users,
    }),
  }).then((res) => checkResponse(res));
};

export const getEvent = ({ id }) => {
  return fetch(`${baseUrl}/events/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const searchByUser = (searchWord) => {
  return fetch(`${baseUrl}/users/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ search: searchWord }),
  }).then((res) => checkResponse(res));
};

export const inviteUsers = (ids, eventId) => {
  return fetch(`${baseUrl}/users/invite-user/${eventId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ users: ids }),
  }).then((res) => checkResponse(res));
};
export const forgotPassword = (ids) => {
  return fetch(`${baseUrl}/users/forgot-password/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ users: ids }),
  }).then((res) => checkResponse(res));
};
export const sendForgotPassword = (email) => {
  return fetch(`${baseUrl}/forgot-password/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  }).then((res) => checkResponse(res));
};

export const addUserToEvent = (eventId, userId) => {
  return fetch(`${baseUrl}/events/${eventId}/user`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ user: userId }),
  }).then((res) => checkResponse(res));
};
export const removeUserFromEvent = (eventId, userId) => {
  return fetch(`${baseUrl}/events/${eventId}/user`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ user: userId }),
  }).then((res) => checkResponse(res));
};

// GET SelectedEvents from UserProfile
export const getUserEvents = ({ eventidarray }) => {
  return fetch(`${baseUrl}/events/selectevents`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      eventidarray,
    }),
  }).then((res) => checkResponse(res));
};

// GET selected user
export const getUser = ({ id }) => {
  return fetch(`${baseUrl}/users/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

// EDIT UserInvites add to user and set invite status automatically to pending rsvp (invited on user homepage)
export const updateUserInviteStatus = ({ id, userinvitedevents }) => {
  return fetch(`${baseUrl}/users/userinvitestatusupdate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      id,
      userinvitedevents,
    }),
  }).then((res) => checkResponse(res));
};

//PATCH Edit Event on page and in database -REQUIRES EXPRESS/BACKEND NODE SETUP
export const editEventInfo = (
  eventid,
  {
    eventname,
    eventstartdate,
    eventenddate,
    eventwelcomemessage,
    eventagenda,
    eventlogo,
  },
) => {
  return fetch(`${baseUrl}/events/${eventid}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      eventname,
      eventstartdate,
      eventenddate,
      eventwelcomemessage,
      eventagenda,
      eventlogo,
    }),
  }).then((res) => checkResponse(res));
};

// PATCH Edit Event Launch Status to set to active or closed
export const editEventLaunchState = ({ eventid, eventlaunchstate }) => {
  return fetch(`${baseUrl}/events/${eventid}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      eventlaunchstate,
    }),
  }).then((res) => checkResponse(res));
};

// PATCH Add Topic to event
export const addEventTopic = ({ eventid, eventtopics }) => {
  return fetch(`${baseUrl}/events/${eventid}/topics`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      ...eventtopics,
    }),
  }).then((res) => checkResponse(res));
};

// GET Topics for selectedEvent
export const getEventTopics = ({ eventidarray }) => {
  return fetch(`${baseUrl}/events/selectedeventtopics`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      eventidarray,
    }),
  }).then((res) => checkResponse(res));
};

// GET Single Event Topic from selected Event
export const getEventTopic = (selectedtopicid) => {
  return fetch(`${baseUrl}/events/selectedeventtopic`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      selectedtopicid,
    }),
  }).then((res) => checkResponse(res));
};

//DELETE Topics from event collection and eventtopics collection
export const deleteTopic = ({ eventid, topicid }) => {
  return fetch(`${baseUrl}/events/${eventid}/deletetopics`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      eventid,
      topicid,
    }),
  }).then((res) => checkResponse(res));
};

// PATCH Add Event Resource to event
export const addEventResource = async ({ eventid, eventresources }) => {
  const file = await getBase64File(eventresources?.eventresourcepdf);
  if (file !== null && file !== "") {
    const pdf = file ? file.split(",").slice(1).join(",") : "";
    return fetch(`${baseUrl}/events/${eventid}/eventresources`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: getToken(),
      },
      body: JSON.stringify({
        ...eventresources,
        contentName: eventresources.eventresourcepdf.name,
        pdfContent: pdf,
        contentType: eventresources.eventresourcepdf.type,
      }),
    }).then((res) => checkResponse(res));
  }
};

// GET Resources for selectedEvent
export const getEventResources = ({ eventidarray }) => {
  return fetch(`${baseUrl}/events/selectedeventresources`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      eventidarray,
    }),
  }).then((res) => checkResponse(res));
};

// DELETE Event resource from event array and eventresources collection
export const deleteEventResource = ({ eventid, resourceid }) => {
  return fetch(`${baseUrl}/events/${eventid}/deleteeventresources`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      resourceid,
    }),
  }).then((res) => checkResponse(res));
};

//PATCH Edit Topic Information for specified topic
export const editSelectedTopic = ({ topicid, updatedtopicinfo }) => {
  return fetch(`${baseUrl}/events/editselectedtopic`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      topicid,
      updatedtopicinfo,
    }),
  }).then((res) => checkResponse(res));
};

//PATCH Edit Topic Information for specified topic
export const launchSelectedTopic = ({ topicid, launchstate }) => {
  return fetch(`${baseUrl}/events/launchselectedtopic`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      topicid,
      launchstate,
    }),
  }).then((res) => checkResponse(res));
};

// GET Discussion Qs for selectedTopic
export const getTopicDiscussionQuestions = ({ topicidarray }) => {
  return fetch(`${baseUrl}/events/selectedtopicdiscussionquestions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      topicidarray,
    }),
  }).then((res) => checkResponse(res));
};

//CREATE NEW discussion questions within topicquestions array
export const addEventTopicDiscussionQuestion = ({
  topicid,
  eventtopicdiscussionquestions,
}) => {
  return fetch(`${baseUrl}/topic/${topicid}/topicdiscussionquestions`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify(eventtopicdiscussionquestions),
  }).then((res) => checkResponse(res));
};

//DELETE DISCUSSION Question
export const deleteTopicDiscussionQuestion = ({ topicid, questionid }) => {
  return fetch(`${baseUrl}/events/${topicid}/deletediscussionquestion`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      topicid,
      questionid,
    }),
  }).then((res) => checkResponse(res));
};

//PATCH Edit Topic Information for specified topic
export const editSelectedDiscussionQuestion = ({
  topicid,
  questionid,
  updateddiscussionquestioninfo,
}) => {
  return fetch(`${baseUrl}/events/editselecteddiscussionquestion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      topicid,
      questionid,
      updateddiscussionquestioninfo,
    }),
  }).then((res) => checkResponse(res));
};

// GET Discussion Qs for selectedTopic
export const getTopicResources = ({ topicidarray }) => {
  return fetch(`${baseUrl}/events/selectedtopicresources`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      topicidarray,
    }),
  }).then((res) => checkResponse(res));
};

//CREATE NEW discussion questions within topicquestions array
export const addEventTopicResource = async ({
  topicid,
  eventtopicresources,
}) => {
  const file = await getBase64File(eventtopicresources.eventtopicresourcepdf);
  if (file !== null && file !== "") {
    const pdf = file ? file.split(",").slice(1).join(",") : "";
    return fetch(`${baseUrl}/events/${topicid}/topicresources`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: getToken(),
      },
      body: JSON.stringify({
        ...eventtopicresources,
        contentName: eventtopicresources.eventtopicresourcepdf.name,
        pdfContent: pdf,
        contentType: eventtopicresources.eventtopicresourcepdf.type,
      }),
    }).then((res) => checkResponse(res));
  }
};

//DELETE Topic Resources
export const deleteTopicResource = ({ topicid, resourceid }) => {
  return fetch(`${baseUrl}/events/${topicid}/deletetopicresource`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      topicid,
      resourceid,
    }),
  }).then((res) => checkResponse(res));
};

//PATCH Edit Topic Information for specified topic
export const launchSelectedDiscussionQuestion = ({
  questionid,
  launchstate,
}) => {
  return fetch(`${baseUrl}/events/launchselecteddiscussionquestion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      questionid,
      launchstate,
    }),
  }).then((res) => checkResponse(res));
};

//CREATE NEW discussion questions within topicquestions array
export const addDiscussionQuestionResponse = ({
  questionid,
  eventdiscussionquestionresponses,
}) => {
  return fetch(`${baseUrl}/events/${questionid}/discussionquestionresponses`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify(eventdiscussionquestionresponses),
  }).then((res) => checkResponse(res));
};

//PATCH Edit Response information for specified response
export const editSelectedResponse = ({ responseid, updatedresponseinfo }) => {
  return fetch(`${baseUrl}/events/editselectedresponse`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ responseid, updatedresponseinfo }),
  }).then((res) => checkResponse(res));
};

// GET Single Event Topic from selected Event
export const getSelectedDiscussionQuestion = ({ selectedquestionid }) => {
  return fetch(`${baseUrl}/events/selecteddiscussionquestion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      selectedquestionid,
    }),
  }).then((res) => checkResponse(res));
};

// GET Responses for selectedDQ
export const getDiscussionQuestionResponses = ({ responseidarray }) => {
  return fetch(`${baseUrl}/events/selecteddiscussionquestionresponses`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      responseidarray,
    }),
  }).then((res) => checkResponse(res));
};

// GET Single Response from selected DiscussionBoardQuestion
export const getSelectedResponse = ({ selectedresponseid }) => {
  return fetch(`${baseUrl}/events/selectedresponse`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      selectedresponseid,
    }),
  }).then((res) => checkResponse(res));
};

//DELETE Response
export const deleteResponse = ({ questionid, responseid }) => {
  return fetch(`${baseUrl}/events/${questionid}/deleteresponse`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      questionid,
      responseid,
    }),
  }).then((res) => checkResponse(res));
};

export const addDiscussionQuestionResponseReply = ({
  responseid,
  discussionquestionresponsereplies,
}) => {
  return fetch(
    `${baseUrl}/events/${responseid}/discussionquestionresponsereplies`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: getToken(),
      },
      body: JSON.stringify(discussionquestionresponsereplies),
    },
  ).then((res) => checkResponse(res));
};

export const getDiscussionQuestionReplies = ({ replyidarray }) => {
  return fetch(`${baseUrl}/events/selecteddiscussionquestionreplies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      replyidarray,
    }),
  }).then((res) => checkResponse(res));
};

export const deleteReply = ({ responseId, replyId }) => {
  return fetch(`${baseUrl}/reply/${replyId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      responseId,
    }),
  }).then((res) => checkResponse(res));
};

export const editSelectedReply = ({ replyid, updatedreplyinfo }) => {
  return fetch(`${baseUrl}/events/editselectedreply`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ replyid, updatedreplyinfo }),
  }).then((res) => checkResponse(res));
};

export const getInvitedUsers = (inviteduseridarray) => {
  return fetch(`${baseUrl}/users/selectedusers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      inviteduseridarray,
    }),
  }).then((res) => checkResponse(res));
};

export const getEventData = ({ selectedeventid }) => {
  return fetch(`${baseUrl}/exports/export`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({
      selectedeventid,
    }),
  }).then((res) => checkResponse(res));
};

export const addResponseLike = (id) => {
  return fetch(`${baseUrl}/events/${id}/likeresponse`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
export const addResponseDislike = (id) => {
  return fetch(`${baseUrl}/events/${id}/dislikeresponse`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const removeResponseLike = (id) => {
  return fetch(`${baseUrl}/events/${id}/removelikeresponse`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
export const removeResponseDislike = (id) => {
  return fetch(`${baseUrl}/events/${id}/removedislikeresponse`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const getEventByTopicId = (topicId) => {
  return fetch(`${baseUrl}/topic/${topicId}/event`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
export const getEventAndEventTopicByDiscussionQuestionId = (id) => {
  return fetch(`${baseUrl}/discussionQuestion/${id}/event`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const allResources = (id) => {
  return fetch(`${baseUrl}/events/${id}/all-resources`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const createReply = (responseId, body) => {
  return fetch(`${baseUrl}/discussionQuestion/${responseId}/reply`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify(body),
  }).then((res) => checkResponse(res));
};

export const getReplies = (responseId) => {
  return fetch(`${baseUrl}/discussionQuestion/${responseId}/reply`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const getDiscussionReport = (responseId) => {
  return fetch(`${baseUrl}/discussionQuestion/${responseId}/report`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const addReplyLike = (id) => {
  return fetch(`${baseUrl}/reply/${id}/like`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
export const addReplyDislike = (id) => {
  return fetch(`${baseUrl}/reply/${id}/dislike`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const removeReplyLike = (id) => {
  return fetch(`${baseUrl}/reply/${id}/remove-like`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
export const removeReplyDislike = (id) => {
  return fetch(`${baseUrl}/reply/${id}/remove-dislike`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const editReply = (id, body) => {
  return fetch(`${baseUrl}/reply/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ replyText: body }),
  }).then((res) => checkResponse(res));
};
export const addPolling = (body) => {
  return fetch(`${baseUrl}/polling`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ ...body }),
  }).then((res) => checkResponse(res));
};

export const getPolling = (questionId) => {
  return fetch(`${baseUrl}/polling/${questionId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const closePolling = (pollingId) => {
  return fetch(`${baseUrl}/polling/${pollingId}/close`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const pollByUser = (poll, selectedOptions) => {
  return fetch(`${baseUrl}/polling/${poll._id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
    body: JSON.stringify({ poll: selectedOptions }),
  }).then((res) => checkResponse(res));
};

export const hasUserPolled = (questionId) => {
  return fetch(`${baseUrl}/polling/${questionId}/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const getAggregatePoll = (questionId) => {
  return fetch(`${baseUrl}/polling/${questionId}/aggregate`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};

export const getPollByUsers = (questionId) => {
  return fetch(`${baseUrl}/polling/${questionId}/details`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: getToken(),
    },
  }).then((res) => checkResponse(res));
};
