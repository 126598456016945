import "./AdminTopicPage.css";
import TopicPageHeader from "../TopicPageHeader/TopicPageHeader";
import AdminTopicPageMain from "../AdminTopicPageMain/AdminTopicPageMain";

function AdminTopicPage() {
  return (
    <>
      <TopicPageHeader />
      <AdminTopicPageMain />
    </>
  );
}

export default AdminTopicPage;
