import React, { useState } from "react";
import "./TopicResourceCardModal.css";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { NewTab } from "../TopicResourceCard/TopicResourceCard";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const TopicResourceCardModal = ({ onClose, file }) => {
  console.log(file);
  const pdforvideo = "pdf";
  console.log(pdfjs.GlobalWorkerOptions.workerSrc);
  console.log(pdfjs.version);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <div className={`modal modal_scroll`}>
      <div className="modal__container-pdf">
        <div className="modal__subcontainer">
          <div className="card__text-title">{file.contentName}</div>
          <button
            className="modal__popup-close"
            type="button"
            onClick={onClose}
          ></button>
        </div>
        {pdforvideo ? (
          <div>
            <NewTab videoinfo={file.fileLink} />
          </div>
        ) : (
          <video className="modal__videoresource" source="" type="" controls>
            Video here
          </video>
        )}
      </div>
    </div>
  );
};

export default TopicResourceCardModal;
