import React from "react";

const DeleteTopicModal = ({ handleTopicDelete, onClose }) => {
  return (
    <div className="modal__confirm-container">
      <p className="modal__confirm-message">
        Are you sure you want to delete this topic?
      </p>
      <button className="button button-error" onClick={handleTopicDelete}>
        Yes, delete topic
      </button>
      <p className="modal__confirm-cancel" onClick={onClose}>
        Cancel
      </p>
    </div>
  );
};

export default DeleteTopicModal;
