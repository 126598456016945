// PollingOptionsComponent.js
import React, { useState } from "react";
import ConfirmPollingResponseModal from "../ConfirmPollingResponseModal/ConfirmPollingResponseModal";
import { pollByUser } from "../../utils/api";
import {useParams} from "react-router-dom";

const PollingOptionsComponent = ({ data, handlePollData, hidePolling, setFetcher, socket, reload }) => {
  const params = useParams();
  const [createNewPollingResponseModal, setCreateNewPollingResponseModal] =
    useState(false);
  const polloptions = data.polls;
  const isPollingOpen = data.isPollingOpen;

  const extractPollingOptions = (polloptions) => {
    return polloptions.map(({ _id, response }) => ({ id: _id, response }));
  };

  const pollingOptions = extractPollingOptions(polloptions);

  const MAX_SELECTIONS = data.max;

  const pollType = data.type;
  console.log(pollType);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCreateNewPollingConfirmModal = () => {
    setCreateNewPollingResponseModal(true)

  };
  const handleCheckboxChange = (optionId) => {

    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(optionId)) {
        return prevSelected.filter((id) => id !== optionId);
      }
      if (prevSelected.length < MAX_SELECTIONS) {
        return [...prevSelected, optionId];
      }
      return prevSelected;
    });
  };

  const handleConfirmPollClose = () => {
    setCreateNewPollingResponseModal(false);
    pollByUser(data, selectedOptions).then(() => {
      setCreateNewPollingResponseModal(true);
      handlePollData();
      socket.emit("room", params.id);
      reload();
      setFetcher(true);
    });
  };

  return (
    !hidePolling &&
    isPollingOpen && (
      <>
        <div className="polling_options">
          {MAX_SELECTIONS != null ? (
            <h3>Select up to {MAX_SELECTIONS} options:</h3>
          ) : (
            <h3>Select 1 option:</h3>
          )}
          {pollingOptions.map((option) => (
            <div key={option.id} className="polling_text">
              {pollType === "checkbox" ? (
                <label>
                  <input
                    type={pollType}
                    checked={selectedOptions.includes(option.id)}
                    onChange={() => handleCheckboxChange(option.id)}
                    disabled={
                      selectedOptions.length >= MAX_SELECTIONS &&
                      !selectedOptions.includes(option.id)
                    }
                  />
                  {option.response}
                </label>
              ) : (
                <label>
                  <input
                    type={pollType}
                    checked={selectedOptions.includes(option.id)}
                    onChange={() => setSelectedOptions(option.id)}
                  />
                  {option.response}
                </label>
              )}
            </div>
          ))}
          <div className="modal__buttons-polling">
            <button
              className="button"
              type="submit"
              onClick={() => {
                handleCreateNewPollingConfirmModal();
              }}
            >
              Submit
            </button>
          </div>
        </div>
        {createNewPollingResponseModal && (
          <ConfirmPollingResponseModal onClose={handleConfirmPollClose} />
        )}
      </>
    )
  );
};

export default PollingOptionsComponent;
