import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables,
  Plugin,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { useParams } from "react-router-dom";
import { getAggregatePoll } from "../../utils/api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels
);

const BarChartComponent = ({ data: unsortedPollResponse }) => {
  const pollResponse = unsortedPollResponse.sort((a, b) => a?.order - b?.order)

  let isPollValid = pollResponse.length > 0;


  const pollresponseoptions = isPollValid
    ? pollResponse.map((option) => option.response)
    : [];
  const pollData = isPollValid
    ? pollResponse.map((option) => option.count)
    : [];

  console.log(pollresponseoptions);

  //Truncated with ... for y axis
  const truncateStrings = (stringsArray, maxLength) => {
    return stringsArray.map((str) => {
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + "...";
      }
      return str;
    });
  };

  const shortPollResponseOptions = truncateStrings(pollresponseoptions, 15);

  const toolTipPollResponseOptions = truncateStrings(pollresponseoptions, 250);

  //Truncate for Tooltip
  const chunkSentence = (text, maxChars) => {
    const words = text?.split(" ");
    const chunks = [];
    let currentLine = "";

    words?.forEach((word) => {
      // Check if adding the next word exceeds the max characters
      if (currentLine.length + word.length + 1 > maxChars) {
        // If it does, push the current line and start a new one
        chunks.push(currentLine.trim());
        currentLine = word; // Start a new line with the current word
      } else {
        // Add the word to the current line
        currentLine += currentLine.length ? ` ${word}` : word;
      }
    });

    // Add the last line if it's not empty
    if (currentLine) {
      chunks.push(currentLine.trim());
    }

    return chunks;
  };

  // const chunkedLines = chunkSentence(pollresponseoptions[0], 60);
  // console.log(chunkedLines);

  const plotdata = {
    // labels: pollresponseoptions,
    labels: shortPollResponseOptions,
    datasets: [
      {
        data: pollData,
        backgroundColor: "#b4297e",
      },
    ],
  };

  console.log(plotdata.labels);
  const yLabels = plotdata.labels;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      tooltip: {
        enabled: true,
        bodyFont: {
          size: 12, // Font size for body text
        },
        padding: 10, // Padding inside the tooltip
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Tooltip background color
        displayColors: false, // Hide the color box
        callbacks: {
          // Remove the title from the tooltip
          title: () => "",
          label: function (context) {
            const index = context.dataIndex;
            const tooltipchunks = chunkSentence(
              toolTipPollResponseOptions[index],
              50
            );
            return tooltipchunks;
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      datalabels: {
        color: "white", // Text color
        anchor: "end", // Anchor position relative to the bar
        align: "start", // Align text to the start of the anchor
        font: {
          weight: "bold", // Bold text
          size: 14, // Font size
        },
        offset: 4, // Offset from the end of the bar
        formatter: (value) => {
          return value;
        },
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          display: false, // Hide x-axis ticks
        },
        title: {
          display: true,
          text: "Response Count",
          color: "#333",
          font: {
            size: 14,
            weight: "bold",
            lineHeight: 1.2,
          },
        },

        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
          text: "Response Options",
        },
        grid: {
          display: false,
        },

        ticks: {
          color: "#333",
          font: {
            size: 14,
            weight: "bold",
          },
          padding: 5,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    isPollValid && (
      <div className="main__poll-section">
        <div className="barchart">
          <Bar data={plotdata} options={options} />
        </div>
      </div>
    )
  );
};

export default BarChartComponent;
