import React, { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

const EditDiscussionQuestionModal = ({
  isOpen,
  onClose,
  onEditDiscussionQuestion,
  currentDiscussionQuestion,
}) => {
  const currentDiscussionQuestionText =
    currentDiscussionQuestion?.eventtopicdiscussionquestion;
  const currentDiscussionQuestionStatus =
    currentDiscussionQuestion?.eventtopicdiscussionstatus;
  const currentDiscussionQuestionBoard =
    currentDiscussionQuestion?.discussionboard;

  //set States
  const [discussionQuestion, setDiscussionQuestion] = useState("");
  const [discussionQuestionStatus, setDiscussionQuestionStatus] =
    useState("prelaunch");
  const [discussionQuestionBoard, setDiscussionQuestionBoard] = useState([]);

  // use a useEffect hook to reset the input field state to current user info when
  useEffect(() => {
    setDiscussionQuestion(currentDiscussionQuestionText);
    setDiscussionQuestionStatus(currentDiscussionQuestionStatus);
    setDiscussionQuestionBoard(currentDiscussionQuestionBoard);
  }, [
    currentDiscussionQuestionText,
    currentDiscussionQuestionStatus,
    currentDiscussionQuestionBoard,
  ]);

  // create onChange handlers corresponding to each state variable (for each setXxx)
  function handleDiscussionQuestionTextChange(evt) {
    setDiscussionQuestion(evt.target.value);
  }

  function handleSubmit(evt) {
    // prevent default behavior
    evt.preventDefault();
    // call onRegisterUser with appropriate arguments - //CHECKE THIShandleAddItemSubmit
    const update_discussionquestion = {};
    update_discussionquestion.eventtopicdiscussionquestion = discussionQuestion;
    onEditDiscussionQuestion(update_discussionquestion);
  }

  // Update Profile form with one button to submit
  return (
    <ModalWithForm
      buttonText="Save Discussion Question Changes"
      title="Edit Discussion Question"
      onClose={onClose}
      name="new-event-card"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <label className="modal__label" id="modal-namelabel">
        Discussion Question
        <textarea
          autoFocus
          className="modal__input textarea"
          id="modal-name"
          type="text"
          name="name"
          minLength="1"
          // maxLength="30"
          placeholder="Name"
          value={discussionQuestion}
          onChange={handleDiscussionQuestionTextChange}
          required
        />
      </label>
      <span className="modal__error" id="modal-link-error"></span>
    </ModalWithForm>
  );
};
export default EditDiscussionQuestionModal;
