import React, { useState } from "react";
import "./EventResourceCard.css";
import DeleteIcon from "../../images/DeleteIcon.svg";
import PDFIcon from "../../images/picture_as_pdf.svg";
import VideoIcon from "../../images/video_file.svg";
import DeleteEventResourceModal from "../DeleteEventResourceModal/DeleteEventResourceModal";
import { deleteEventResource } from "../../utils/api";
import EventResourceCardModal from "../EventResourceCardModal/EventResourceCardModal";
import { NewTab } from "../TopicResourceCard/TopicResourceCard";
import { getCurrentUserRole } from "../../utils/auth";

const EventResourceCard = ({ item, event, reload }) => {
  const [deleteEventResourceModal, setDeleteEventResourceModal] =
    useState(false);

  const [previewPdf, setPreviewPdf] = useState(false);

  const videoinfo = item.eventresourcevideo;

  const adminoruser = getCurrentUserRole();

  const handleEventResourceDelete = () => {
    deleteEventResource({
      eventid: event._id,
      resourceid: item._id,
    }).then(() => {
      reload();
    });
  };
  return (
    <>
      <div className="resourcecard">
        <div className="resourcecard-container">
          {videoinfo === "" ? (
            <NewTab videoinfo={item.fileLink} pdf />
          ) : (
            <NewTab videoinfo={videoinfo} />
          )}
          <div className="card__text-title">{item.eventresourcename}</div>
        </div>
        {adminoruser === "admin" ? (
          <div className="resourcecard-icon-container">
            <button
              type="button"
              className="button-icon"
              onClick={() => setDeleteEventResourceModal(true)}
            >
              <img className="icon" src={DeleteIcon} alt="delete event" />
            </button>
          </div>
          ) : (
            <></>
          )}
      </div>
      {deleteEventResourceModal && (
        <DeleteEventResourceModal
          handleEventResourceDelete={handleEventResourceDelete}
          onClose={() => setDeleteEventResourceModal(false)}
        />
      )}
      {previewPdf && (
        <EventResourceCardModal
          onClose={() => setPreviewPdf(false)}
          file={item}
        />
      )}
    </>
  );
};

export default EventResourceCard;
