//Event Card Component
import React from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "./EventCard.css";
import DeleteIcon from "../../images/DeleteIcon.svg";
import GoToIcon from "../../images/GoToIcon.svg";

const EventCard = ({ eventinfo, onSelectCard, handleDeleteEvent }) => {
  const navigate = useNavigate();

  const eventsPage = () => {
    navigate(`/adminevent/${eventinfo._id}`);
  };

  const event_startdate = dayjs(eventinfo?.eventstartdate).format("MM-DD-YYYY");
  const event_enddate = dayjs(eventinfo?.eventenddate).format("MM-DD-YYYY");

  // SET AS IS USER VS IS ADMIN to have different button views on eventcard render in AdminProfileMain/UserProfileMain
  return (
    <div className="card">
      <div className="card__text-container">
        <h3 className="card__text-title">{eventinfo.eventname}</h3>
        <p className="card__text-date">
          {event_startdate} TO {event_enddate}
        </p>
        <p className="card__text">
          Users: {eventinfo?.eventinvitedusers?.length} invited
        </p>
        <button
          className="button-icon"
          type="button"
          //   disabled={disableButton ? true : false}
          onClick={() => {
            onSelectCard(eventinfo);
            eventsPage();
          }}
        >
          <img className=" icon-enlarge" src={GoToIcon} alt="Go to event" />
        </button>
      </div>
      <div className="card__statusicons-container">
        <p className="card__text-status"> {eventinfo?.eventlaunchstate}</p>
        <div className="icon-container">
          <button
            type="button"
            className="button-icon"
            onClick={() => {
              handleDeleteEvent(eventinfo?._id);
            }}
          >
            <img className="icon" src={DeleteIcon} alt="delete event" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
